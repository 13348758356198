.medicalTeacher__data {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 17% 83%;
  gap: 0px;
  align-items: flex-start;
  justify-content: center;
  padding-right: 18px;
}
.teacher__list {
  margin-top: 85px;
}
.remove-cursor {
  cursor: default !important;
}

.add-cursor {
  cursor: pointer !important;
}
#teacherScroll.weeklyChildTable__container .week__btn.sliderLeft {
  left: 44%;
}
.teacher__list__scroll {
  overflow: initial !important;
}
.teacherRFI_btn .btn-select .btnAdopted,
.RFI_btn .btn-select .btnAdopted {
  margin: 0 auto;
}
/* responsive css */
@media screen and (max-width: 1700px) {
  .medicalTeacher__data {
    padding-right: 0;
  }
}
@media screen and (max-width: 1380px) {
  .medicalTeacher__data {
    grid-template-columns: 23% 77%;
  }
}
@media screen and (max-width: 992px) {
  .medicalTeacher__data {
    grid-template-columns: 0% 100%;
  }
}
