.add_respondent{
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.mgmt__col1 {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    color: #414f63;
    position: relative;
  }
  .mgmt__col1 .mgmtUser-icon {
    width: 35px;
    height: 35px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-right: 10px;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.5px;
    color: var(--color-slaty);
    background-color: #dedab7;
  }
  .mgmtName-role {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: var(--color-slaty);
    font-size: 13px;
  }
  .mgmtName-role .mgmt__line1 {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 2px;
  }
  .mgmtName-role .mgmt__line2 {
    font-weight: 400;
    font-size: 12px;
    /* width: 130px; */
    /* text-overflow: ellipsis; */
    white-space: nowrap;
    /* overflow: hidden; */
    line-height: 15px;
  }
  .mgmtName-role a {
    color: var(--color-slaty);
  }
  .mgmtName-role a:hover {
    color: var(--color-primary);
  }
  .fa-ellipsis-v {
    font-size: 29px;
    color: var(--color-lightWhite);
    cursor: pointer;
  }
  .mgmt__table tbody > tr > td {
    background-color: var(--color-white);
    border-bottom: 1px solid var(--color-greyish1);
    border-top: 0;
  }
  .mgmt__table th,
  .mgmt__table td {
    width: 11%;
  }
  .mgmt__table th {
    background-color: var(--color-lightGrey);
    border-top: 0;
    border-bottom: 0 !important;
    font-size: 13px;
    color: #7c899d;
    letter-spacing: 0.5px;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 1;
    height: 55px;
    vertical-align: middle !important;
  }
  .mgmt__table th span {
    overflow-wrap: break-word;
  }
  .mgmt__table .ant-table-column-sorter {
    color: var(--color-slimBlack);
  }
  .mgmt__address {
    font-size: 14px;
    color: var(--color-slaty);
    letter-spacing: 0.5px;
    line-height: 16px;
    /* white-space: nowrap; */
  }
  .mgmt__table .ant-table-column-sorter-up.active,
  .mgmt__table .ant-table-column-sorter-down.active {
    color: var(--color-primary);
  }
  .mgmt__popovers .ant-popover-inner-content {
    padding: 12px 0;
  }
  .mgmt__popover {
    width: 200px;
    margin: auto;
  }
  .mgmt__popover ul {
    padding: 0;
    list-style: none;
  }
  .mgmt__popover ul li {
    padding: 7px 10px;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-slaty);
    text-align: left;
    cursor: pointer;
  }
  .mgmt__popover ul li img {
    margin-right: 10px;
    width: 16px;
  }
  .mgmt__popover ul li:hover {
    color: var(--color-primary);
  }
  .mgmt__table {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: auto;
    height: calc(100vh - 313px);
    min-height: calc(100vh - 313px);
  }
  .mgmt__table .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid var(--color-primary);
    /* border-top: 0; */
    /* border-left: 0; */
    transform: rotate(90deg);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: " ";
    background-color: var(--color-primary);
    left: 4px;
    top: 3.5px;
    width: 6px;
    height: 7px;
  }
  .mgmt__table .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-white);
    border-color: var(--color-primary);
  }
  .mgmtTable__sorters {
    display: inline-flex;
    align-items: center;
  }
  .sorter {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-left: 8px;
  }
  .mgmt__caret {
    line-height: 8px;
    display: inline-block;
    cursor: pointer;
    text-rendering: optimizeLegibility;
    color: var(--color-slimBlack);
  }
  .caretActive {
    line-height: 8px;
    display: inline-block;
    cursor: pointer;
    text-rendering: optimizeLegibility;
  }
  .mgmt__caret.fa-caret-up:hover,
  .fa-caret-up.caretActive {
    color: var(--color-primary);
  }
  .mgmt__caret.fa-caret-down:hover,
  .fa-caret-down.caretActive {
    color: var(--color-primary);
  }
  label {
    font-weight: bold;
    display: flex;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="date"] {
    display: block;
    
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .error {
    color: red;
    font-size: 12px;
    text-align: left;
  }
  
  .form-control {
    margin-bottom: 20px;
  }
  .btnGender_unselected{
    background-color:white ;
    margin-left: 3pt;
  }
  .btnGender_selected{
    background-color: #99cfee;
    margin-left: 3pt;
  }
  .ant-btn-dangerous{
    background-color: rgb(248, 93, 93);
  }



  
  @media screen and (max-width: 1500px) {
    .mgmt__table th {
      font-size: 12px;
    }
  }
  