.medication {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 25% 73%;
  grid-gap: 30px;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;
  padding: 0 20px 0 0;
}
.medication__form {
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: var(--color-shadeSlaty);
  padding: 15px 0 15px 12px;
}
.medSet__form{
  overflow: auto;
  height: calc(100vh - 85px);
  min-height: calc(100vh - 85px);
}
.medication__form label.form-label, .medication__form input{
  background-color: var(--color-shadeSlaty);
}
.medication__form__wrap{
  padding-right: 12px;
}
.heading.medicationHeading__style {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 35px;
  font-weight: 600;
}
.medication-grp {
  display: inherit;
  margin-bottom: 25px;
}
.med_formDelt{
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 92% 36px;
  align-items: center;
  justify-content: center;
}

.med_fam{
  width: 99% !important;
}
.med_arrow {
  position: absolute;
  right: 10px;
  top: 23px;
  bottom: 0;
  color: #b5c3cf;
  font-size: 24px;
}
.medical-group .form-control.phone_border {
  outline: none;
}
.medical-group .form-control.phone_border:hover,
.medical-group .form-control.phone_border:focus {
  border-color: var(--color-slaty);
  box-shadow: none;
}
.button_wrapper {
  width: 92%;
  margin: 0;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
  justify-content: center;
}
.modal__row .ant-btn,
.modal__row .ant-btn:focus,
.modal__row .ant-btn:active,
.modal__row .ant-btn:hover,
.modal__row .ant-btn-primary,
.modal__row .ant-btn-primary:focus,
.modal__row .ant-btn-primary:active,
.modal__row .ant-btn-primary:hover {
  height: 35px !important;
}
.btn_reset.ant-btn-primary,
.btn_reset.ant-btn-primary:focus,
.btn_reset.ant-btn-primary:active,
.modal__row .ant-btn,
.modal__row .ant-btn:focus,
.modal__row .ant-btn:active {
  border-radius: 5px;
  height: 56px;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: var(--color-primary);
  background-color: var(--color-lightBlue);
  border-color: var(--color-lightBlue);
  font-weight: 600;
  margin-right: 10px;
}
.btn_reset.ant-btn.ant-btn-primary:hover,
.modal__row .ant-btn:hover 
{
  color: var(--color-primary);
  background: var(--color-lightBlue);
  border-color: var(--color-primary);
}
.btn_add.ant-btn-primary,
.btn_add.ant-btn-primary:focus,
.btn_add.ant-btn-primary:active,
.modal__row .ant-btn-primary,
.modal__row .ant-btn-primary:focus,
.modal__row .ant-btn-primary:active {
  border-radius: 5px;
  height: 56px;
  font-size: 14px;
  letter-spacing: 0.5px;
  border-color: var(--color-primary);
  background-color: var(--color-primary);
  color: var(--color-white);
  font-weight: 600;
}
.btn_add.ant-btn-primary:hover,
.modal__row .ant-btn-primary:hover {
  color: var(--color-white);
  background: var(--color-primaryDark);
  /* border-color: var(--color-lightBlue); */
}
.med__table {
  position: relative;
}
.med__table .ant-table-thead > tr > th {
  background-color: var(--color-lightGrey);
  font-size: 14px;
  color: var(--color-slimBlack);
  letter-spacing: 0.5px;
}

.med__table .ant-table-tbody > tr > td {
  color: var(--color-slaty);
  font-weight: 600;
  padding: 18px 16px;
}
.med__table .ant-table-tbody > tr.ant-table-row:hover > td, .clickRowStyl{
  background-color: var(--color-gray4);
}
.med__table td.ant-table-column-sort{
  background-color: var(--color-white);
}
i.fa-pen.edit {
  color: var(--color-primary);
  cursor: pointer;
  font-size: 16px;
  padding-left: 10px;
}
i.fa-trash-alt.delete {
  font-size: 16px;
  cursor: pointer;
  color: var(--color-slimRed);
  padding-right: 7px;
}
.edit-icon{
  cursor: pointer;
}
.header-section {
  margin-bottom: 20px;
}
.header-title {
  font-size: 25px;
  font-weight: bold;
  color: #414f63;
}
.cell-data-color {
  font-family: "Raleway", sans-serif !important;
  color: var(--color-slaty);
}
.page-list {
  margin-top: 17px;
}
.saving-form {
  margin-left: -74px;
}
.med_loader {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  background: white;
  left: 0;
  right: 0;
  opacity: 0.5;
}
.edit-icon {
  padding-left: 7px;
}
.noRecord__table h2{
  margin: 10px auto;
  font-size: 20px;
  font-weight: 700;
}
.noRecord__table h2, .noRecord__table p{
    font-style: italic;
    text-align: center;
    color: var(--color-slaty);
    letter-spacing: 0.5px;
}
.noRecord__table p{
  font-size: 14px;
  font-weight: 500;
  padding-right: 10px;
}
.heading.medSet__dosageHead{
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}
p.medSet__dosageSubhead{
  font-weight: 500;
  font-size: 12px;
  width: 92%;
}
.mgmt__leftFilters.mgmtSet__filters {
    grid-template-columns: 4fr 1fr 1fr;
}

.applyBtn {
    margin-top: 6px;
}

    .applyBtn .week__note.ant-btn-primary {
        height: 50px;
        width: 100%;
    }

.med__col1 {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    color: #414f63;
    position: relative;
}

    .med__col1 .medUser-icon {
        width: 35px;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-right: 10px;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.5px;
        color: var(--color-slaty);
        background-color: #dedab7;
    }

.medName-role {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    color: var(--color-slaty);
}

    .medName-role .med__line1 {
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 2px;
    }

    .medName-role .__line2 {
        font-weight: 400;
        font-size: 12px;
        /* width: 130px; */
        /* text-overflow: ellipsis; */
        white-space: nowrap;
        /* overflow: hidden; */
        line-height: 15px;
    }

    .medName-role a {
        color: var(--color-slaty);
    }

        .medName-role a:hover {
            color: var(--color-primary);
        }

.fa-ellipsis-v {
    font-size: 20px;
    color: var(--color-lightWhite);
    cursor: pointer;
}

.med__table tbody > tr > td {
    
    border-bottom: 1px solid var(--color-greyish1);
    border-top: 0;
}

.med__table th,
.med__table td {
    width: 11%;
}

.med__table th {
    background-color: var(--color-lightGrey);
    border-top: 0;
    border-bottom: 0 !important;
    font-size: 13px;
    color: #7c899d;
    letter-spacing: 0.5px;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 1;
    height: 55px;
    vertical-align: middle !important;
}

    .med__table th span {
        overflow-wrap: break-word;
    }

.med__table .ant-table-column-sorter {
    color: var(--color-slimBlack);
}

.med__address {
    font-size: 14px;
    color: var(--color-slaty);
    letter-spacing: 0.5px;
    line-height: 16px;
    /* white-space: nowrap; */
}

.med__table .ant-table-column-sorter-up.active,
.med__table .ant-table-column-sorter-down.active {
    color: var(--color-primary);
}

.med__popovers .ant-popover-inner-content {
    padding: 12px 0;
}

.med__popover {
    width: 200px;
    margin: auto;
}

    .med__popover ul {
        padding: 0;
        list-style: none;
    }

        .med__popover ul li {
            padding: 7px 10px;
            font-size: 14px;
            font-weight: 600;
            color: var(--color-slaty);
            text-align: left;
            cursor: pointer;
        }

            .med__popover ul li img {
                margin-right: 10px;
                width: 16px;
            }

            .med__popover ul li:hover {
                color: var(--color-primary);
            }

.med__table {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: auto;
    height: calc(100vh - 313px);
    min-height: calc(100vh - 313px);
}

    .med__table .ant-checkbox-checked .ant-checkbox-inner::after {
        position: absolute;
        display: table;
        border: 2px solid var(--color-primary);
        /* border-top: 0; */
        /* border-left: 0; */
        transform: rotate(90deg);
        opacity: 1;
        -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
        content: " ";
        background-color: var(--color-primary);
        left: 4px;
        top: 3.5px;
        width: 6px;
        height: 7px;
    }

    .med__table .ant-checkbox-checked .ant-checkbox-inner {
        background-color: var(--color-white);
        border-color: var(--color-primary);
    }

.medTable__sorters {
    display: inline-flex;
    align-items: center;
}

.sorter {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    margin-left: 8px;
}

.med__caret {
    line-height: 8px;
    display: inline-block;
    cursor: pointer;
    text-rendering: optimizeLegibility;
    color: var(--color-slimBlack);
}

.caretActive {
    line-height: 8px;
    display: inline-block;
    cursor: pointer;
    text-rendering: optimizeLegibility;
}

.med__caret.fa-caret-up:hover,
.fa-caret-up.caretActive {
    color: var(--color-primary);
}

.med__caret.fa-caret-down:hover,
.fa-caret-down.caretActive {
    color: var(--color-primary);
}
.row_active {
    background-color: #E5E2E2;
}
/* responsive css */
@media screen and (max-width: 1500px) {
    .med_formDelt{
    grid-template-columns: 92% 27px;
  }
}
@media screen and (max-width: 1280px){
  .medication{
    gap: 10px;
    grid-template-columns: 30% 68%;
  }
  .heading.medicationHeading__style{
    font-size: 14px;
  }
  .medication__form {
    padding: 20px 0 20px 5px;
  }
}
@media screen and (max-width: 1024px){
  .heading.medicationHeading__style{
    font-size: 13px;
  }
  .button_wrapper{
    gap: 10px;
  }
  .med_formDelt{
    grid-template-columns: 92% 20px;
  }
}
@media screen and (max-width:992px){
  .heading.medicationHeading__style{
    line-height: 18px;
  }
  /* .med_formDelt{
    grid-template-columns: 11fr 20px;
  } */
}