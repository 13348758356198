.weekwise__block{
    width: 100%;
    padding: 20px 20px;
    border-color: var(--color-fadeOrange);
    border-left-width: 5px;
    border-left-style: solid;
    position: relative;
    height: 110px;
    min-height: 100px;
    text-align: center;
}
.weekwise__block.weekModerate{
    border-color: var(--color-fadeLightOrange);
}
.weekwise__block.weekExtreme{
    border-color: var(--color-reddish);
}
.weekwise__block.weekMissedOut{
    background-color: var(--color-lightCrayon);
}
.weekwise__block.weekExtreme h5, .weekwise__block.weekModerate h5{
    margin-bottom: 3px;
}
.weekwise__block.weekwise__block.weekModerate p, .weekwise__block.weekExtreme p{
    margin-bottom: 7px;
}
.weekwise__block p, .weekwise__block h5{
    font-size: 12px;
    letter-spacing: 0.5px;
}
.weekwise__block p{
    margin-bottom: 4px;
    color: var(--color-navyBlue);
}
.weekwise__block h5{
    font-weight: bold;
    color: var(--color-slaty);
    border-radius: 15px;
    padding: 8px 0;
    width: fit-content;
    margin: 0 auto;
    text-align: center;
    display: inline-block;
}
.dot{
    border-radius: 50%;
    display: inline-block;
}
.dot.weekOrange, .dot.weekExtremeDot{
    height: 10px;
    width: 10px;
    margin-right: 6px;
}
.dot.weekOrange{
    background-color: var(--color-fadeLightOrange);
}
.dot.weekExtremeDot{
    background-color: var(--color-reddish);
}
.week__missed{
    font-size: 12px;
    color: var(--color-darkRed);
    font-style: italic;
    text-align: center;
    width: 100%;
    display: inline-block;
}
.addSeverity{
    text-align: center;
    font-size: 20px;
    color: var(--color-primary);
    cursor: pointer;
}
.addSeverityDisabled{
    text-align: center;
    font-size: 20px;
    color: var(--color-gray3);
    cursor: pointer;
}
.weekwise__block.transBorder{
    border-color: transparent;
}
.week__popover.popover__align{
    display: inline-block;
}
/* .week__popover{
    position: relative;
} */
@media screen and (max-width: 1600px){
    .weekwise__block{
        padding: 20px 10px;
    }
}
@media screen and (max-width: 1480px){
    .weekwise__block p, .weekwise__block h5{
        font-size: 10px;
    }
    .weekwise__block{
        padding: 20px 10px;
    }
}
@media screen and (max-width: 1250px){
    .weekwise__block {
        padding: 20px 5px;
    }
}