body {
  margin: 0;
  font-family: "Raleway", sans-serif !important;
  color: #414f63;
}
.brand-color {
  color: #0078bf !important;
}
.left{
  float: left;
}
.right{
  float: right;
}
.list__heading__search{
  display: flex;
  gap: 15px;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-right: 12px;
  position: relative;
  .fa-angle-down.relation_arrow{
    top: 6px;
    right: 22px;
  }
}
.list__heading__search input, .list__heading__search select{
  background-color: var(--color-shadeSlaty);
}
.list__heading__search select{
  height: 38px;
  border: 1px solid var(--color-dimGrey);
  border-radius: 30px;
  outline: none;
  font-size: 14px;
  color: var(--color-darkSlaty);
  position: relative;
}
.pointer {
  cursor: pointer;
}
.padd__hoz{
  padding: 0 20px;
}
.ant-picker.ant-picker-disabled{
  background: #f4f4f4 !important;
}
.main-sidebar {
  // position: fixed;
  // left: 0px;
  // top: 0px;
  // max-width: 80px;
  background: #0078bf;
  width: 100%;
  height: 100%;
  padding-right: 0px;
  z-index: 999;
  border-radius: 0px;
  .sidebar-brand {
    width: 100%;
    text-align: right;
    padding-top: 12px;
    font-size: 23px;
    text-align: center;
    margin-bottom: 80px;
  }
  .sidebar-menu {
    padding: 0px;
    margin: 0px;
    // margin-top: 80px;
    li {
      display: block;
      margin-bottom: 0px;
      margin-top: 0;
      a {
        display: block;
        position: relative;
        padding: 35px 22px;
        &.dashboard {
          background-image: url("./assets/images/svg-icons/home-w.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
        }
        &.dashboard.active {
          background-image: url("./assets/images/svg-icons/home-b.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.dashboard:hover {
          background-image: url("./assets/images/svg-icons/home-b.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.management {
          background-image: url("./assets/images/svg-icons/patient_management.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
        }
        &.management.active {
          background-image: url("./assets/images/svg-icons/patient_management-blue.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.management:hover {
          background-image: url("./assets/images/svg-icons/patient_management-blue.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.management-add {
          background-image: url("./assets/images/svg-icons/add_patient.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
        }
        &.management-add.active {
          background-image: url("./assets/images/svg-icons/add_patient_blue.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.management-add:hover {
          background-image: url("./assets/images/svg-icons/add_patient_blue.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.management-dashboard{
          background-image: url("./assets/images/svg-icons/patient_view.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
        }
        &.management-dashboard.active {
          background-image: url("./assets/images/svg-icons/patient_view_blue.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.management-dashboard:hover {
          background-image: url("./assets/images/svg-icons/patient_view_blue.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.clinician-add {
          background-image: url("./assets/images/svg-icons/Clinician_white_user.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
        }
        &.clinician-add.active {
          background-image: url("./assets/images/svg-icons/Clinician.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.clinician-add:hover {
          background-image: url("./assets/images/svg-icons/Clinician.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.add-patient {
          background-image: url("./assets/images/svg-icons/user_view.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
        }
        &.add-patient.active {
          background-image: url("./assets/images/svg-icons/add_user_blue.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.add-patient:hover {
          background-image: url("./assets/images/svg-icons/add_user_blue.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.medication-setting {
          background-image: url("./assets/images/svg-icons/settings.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
        }
        &.medication-setting.active {
          background-image: url("./assets/images/svg-icons/settings_blue.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
        &.medication-setting:hover {
          background-image: url("./assets/images/svg-icons/settings_blue.svg") !important;
          background-repeat: no-repeat !important;
          background-size: 24px 24px !important;
          background-position: 26px 24px !important;
          background-color: #fff;
          border-radius: 0px;
        }
      }
    }
  }
}

.medical-group.optional-field{
  span.optional {
    position: absolute;
    right: 2px;
    top: -5px;
    letter-spacing: 0.5px;
    font-size: 12px;
    color: var(--color-lightGray8);
  }

  .form-control {
    border: 0.7px solid #9ca6b3;
  }
}
.optional-field {
  span.optional {
    position: absolute;
    right: 2px;
    top: -16px;
    letter-spacing: 0.5px;
    font-size: 10px;
    color: var(--color-slaty);
  }

  .form-control {
    border: 0.7px solid #9ca6b3;
  }
}
.form-control[readonly],
.form-control:disabled {
  background-color: #f4f4f4;
}

$color-delete: #e34332;
.invalid-feedback {
  color: $color-delete;
  // position: absolute;
  // bottom: -20px;
  // font-size: 13px;
  // letter-spacing: 0.2px;
  // white-space: nowrap;
  // word-break: break-all;
  // text-align: left;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus{
    -webkit-box-shadow: 0 0 0px 1000px #f5f5f5 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form-control:focus{
  box-shadow: 0 0 0 0;
}
.Toastify__toast{
  border-radius: 5px !important;
  font-size: 14px !important;
  letter-spacing: 0.5px !important;
  color: var(--color-white) !important;
  font-weight: 600 !important;
  padding-left: 15px !important;
}
.ant-input:focus, .ant-input-focused, .ant-input:hover{
  border-color: var(--color-dimGrey) !important;
  box-shadow: 0 0 0 0 !important;
}
// responsive code
@media screen and (max-width:1500px){
  .list__heading__search select{
    font-size: 12px;
  }
}
@media screen and (max-width: 1320px){
  .invalid-feedback{
    font-size: 11px;
  }
}
@media screen and (max-width:1250px){
  .list__heading__search{
    gap: 7px;
  }
}

@media screen and (max-width:1180px){
  .list__heading__search{
    // display: block;
    padding: 0 8px;
  }
  .invalid-feedback{
    font-size: 10px;
  }
}

@media screen and (max-width:860px){
  .list__heading__search .fa-angle-down.relation_arrow{
    right: 15px;
  }
  .list__heading__search select {
    padding: 0.375rem;
  }
}
.form-control:focus{
  background-color: #fff;
}
.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover, 
.form-control:-webkit-autofill:focus, 
.form-control:-webkit-autofill:active
{
 -webkit-box-shadow: none !important;
 box-shadow: none !important;
}

.search:-webkit-autofill,
.search:-webkit-autofill:hover, 
.search:-webkit-autofill:focus, 
.search:-webkit-autofill:active
{
 -webkit-box-shadow: none !important;
 box-shadow: none !important;
}
.text-area-box{
  min-height: 120px;
}
.medication-box{
  display: block;
  .viewMed__weekContentWrap{
    min-width: 19%;
    display: inline-block;
    margin-right: 9px;
    max-width: 19%;
    vertical-align: top;
    .viewMed__weekContent{
      min-height: 285px;
    }
  }
}