.SMH__container{
    width: 100%;
    margin: 0 auto;
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 3fr 9fr;
    gap: 10px;
    align-items: flex-start;
    justify-content: center;
}
.SMH__left{
    background-color: var(--color-grayShade);
    padding: 20px 15px;
}
.dev_table__btn{
    display: inline-block;
    text-align: right;
}
.dev_table__btn.btn-select .btn-addon .btn{
    font-size: 12px;
    min-height: 40px;
    height: auto;
}
.SMH__content, .SMH__content_row, .SMH__accord__content, .SMH__accord__sibContent, .SMH__accord__homeContent{
    width: 100%;
    margin: 20px auto;
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.SMH__accord__homeContent{
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
}
.SMH__accord__sibContent{
    grid-template-columns: repeat(4, 1fr);
    align-items: flex-start;
}
.SMH__accord__content{
    grid-template-columns: 3fr 6fr 3fr;
     align-items: flex-start;
}
.SMH__content{
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
}
.SMH__content_row{
    grid-template-columns: 1fr;
}
.heading.medicalGeneralDetail__style{
    font-size: 16px;
    color: var(--color-primary);
    line-height: 19px;
    font-weight: 600;
}
.guardian_detail.guardianStyle p{
    font-size: 13px;
    margin: 0;
}
.family__member{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: var(--color-slaty);
    text-decoration: underline;
}
.historyViewHeader.guardian_detail p{
    color: var(--color-slaty);
    font-size: 12px;
    font-weight: bold;
}
.historyViewHeader.guardian_detail p.guardianP2{
    color: var(--color-darkSlaty);
    font-size: 12px;
    font-weight: 500;
}
.guardian_detail h5.historyViewH5{
    font-size: 14px;
    font-weight: 500;
    color: var(--color-darkSlaty);
}
.btn_add.ant-btn-background-ghost.ant-btn-primary{
    border-radius: 13px;
    height: 25px;
    line-height: 25px;
    color: var(--color-primary);
    border-color: var(--color-primary);
    font-size: 12px;
    width: 100%;
    margin: 0 auto;
    background-color: var(--color-grayShade);
}
.btn_add.close_btn.ant-btn-background-ghost.ant-btn-primary{
    border-color: var(--color-darkRed);
    color: var(--color-darkRed);
}
.editForm__img{
    float: left;
    padding-left: 10px;
    width: 25px;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    padding-top: 4px;
}
.learning__table, .developmental__table{
    width: 100%;
    margin: 0 auto;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
    /* border-radius: 4px; */
    display: grid;
    grid-template-rows: 1fr;
    align-items: flex-start;
    justify-content: center;
    gap: 10px;
    padding: 5px 10px;
    /* height: 55px; */
    border-bottom: 0;
}
.learning__table:last-child, .developmental__table:last-child{
    border-bottom: 1px solid rgba(0,0,0, 0.1);
}
.learning__table{
    grid-template-columns: repeat(2, 1fr);
}
.developmental__table{
    grid-template-columns: 8fr 2fr 2fr;
}
.childDev__table{
    grid-template-columns: 5fr 7fr;
    align-items: center;
}

.slideup {
    /* overflow-y: hidden;
	max-height: 0px; 
	transition-property: all;
	transition-duration: 1s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1); */
    transition: slide-up 2s ease-out; 
    height: 100%;
    position: relative;
    overflow: hidden;
}
.slidedown {            
    /* max-height: 320px ;   
    transition-property: all;
	transition-duration: 1s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);*/
    /* transition: all 2s ease-out; */
    transition: slide-down 2s ease-out; 
    height: 100%;
    position: relative;
    overflow: hidden;
    /* transform: translateY(100%); */
    /* transition: 1s ease-in-out; */
}  
@keyframes slide-down {
    0% { opacity: 0; transform: translateY(0%); }   
  100% { opacity: 1; transform: translateY(100%); }
}
@keyframes slide-up {
    0% { opacity: 0; transform: translateY(100%); }   
  100% { opacity: 1; transform: translateY(0%); }
}

/* .slideup, .slidedown{
    overflow: hidden;
    transform: translateY(-100%);
    transition: .4s ease-in-out;
}
.slidedown{
    /* transform: translateY(0); */
/* } */
@media screen and (max-width: 1400px){
    .dev_table__btn.btn-select .btn-addon .btn{
        min-width: 50px;
        font-size: 12px;
        padding: 5px 8px;
    }
}
@media screen and (max-width: 1280px){
    .guardian_detail h5.historyViewH5{
        font-size: 12px;
    }
    .btn_add.ant-btn-background-ghost.ant-btn-primary, 
    .historyViewHeader.guardian_detail p, 
    .historyViewHeader.guardian_detail p.guardianP2{
        font-size: 11px;
    }
}
@media screen and (max-width: 1180px){
    .dev_table__btn.btn-select .btn-addon .btn{
       font-size: 11px;
    }
    .SMH__accord__sibContent, .SMH__accord__content{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media screen and (max-width:992px){
    .SMH__container{
        grid-template-columns: 1fr;
    }
}