.sev__modal{
   width: 100%;
   margin: 0 auto;
   position: relative;
}
.sevr__para{
    font-size: 12px;
    font-weight: 600;
    color: var(--color-darkSlaty);
    letter-spacing: 0.5px;
}
.add__severity{
    width: 100%;
    display: block;
    background-color: var(--color-lightGrey);
    border-radius: 5px;
    font-size: 12px;
    color: var(--color-slaty);
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 8px auto;
    text-align: left;
    border: 1px solid var(--color-lightGrey);
}
.add__severity.ant-btn-primary:hover{
    border-color: var(--color-primary);
    background-color: var(--color-primary);
    color: var(--color-white);
}
.add__severity__orangeColor{
    /* background-color: var(--color-fadeLightOrange); */
    border: 1px solid var(--color-fadeLightOrange);
}
.add__severity__redColor{
    /* background-color: var(--color-reddish); */
    border: 1px solid var(--color-reddish);
}
@media screen and (max-width: 992px){
    .add__severity{
        font-size: 10px;
        height: 25px;
    }
    .sevr__para{
        font-size: 10px;
        margin: 0;
    }
}