.week__wrapper{
    width: 100%;
    margin: 0 auto 40px;
    position: relative;
    text-align: center;
    background-color: var(--color-shadeGray);
    padding: 15px;
    border-radius: 5px;
}
.week__wrapper h5, .week__wrapper p{
    font-size: 16px;
    color: var(--color-slaty);
    line-height: 19px;
    letter-spacing: 0.5px;
}
.week__wrapper h5{
    font-weight: 700;
}
.week__wrapper p{
    font-weight: 500;
}
.weekcard_wrapper{
    width: 100%;
    margin: 30px auto;
    position: relative;
}
@media screen and (max-width:992px){
    .week__wrapper{
        padding: 15px 7px;
    }
}