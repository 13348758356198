.weeklyRating__table{
    width: 100%;
    margin: 0 auto;
    position: relative;
    border: 1px solid var(--color-greyish2);
    border-left: 0;
    padding: 10px;
    overflow: auto;
}
.weeklyTable__container.weekRatingTable{
    border: 1px solid var(--color-greyish2);
    /* height: 100%;
    min-height: 100%; */
}
.weeklyTable__container.weekRatingTable tr.side__effects th{
    padding: 10px 5px;
    border-bottom: 1px solid var(--color-greyish2);
}
.heading.rating__heading{
    position: relative;
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin: 0;
    line-height: 16px;
}
p.rating__subHead{
    margin: 0;
    font-size: 10px;
    font-weight: 400;
}
span span.ratingSev__dot, span span.ratingSev__dot.dot__one{
    padding: 0 !important;
}
span span.ratingSev__dot.dot__seven, span span.ratingSev__dot.dot__five{
    border-radius: 20px;
    width: 8px;
    height: 8px !important;
    border-radius: 50%;
    background: black;
    margin-right: 7px;
}
span span.ratingSev__dot.dot__seven{
    background-color: var(--color-reddish);
}
span span.ratingSev__dot.dot__five{
    background-color: var(--color-fadeLightOrange);
}
.heading.weekTarget__head{
    font-size: 10px;
    margin: 0;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.4px;
}
.weeklyTable__container.weekRatingTable th, .weeklyTable__container.weekRatingTable td{
    width: 5%;
}
.weeklyTable__container.weekRatingTable tr.side__effects th:first-child{
    background-color: var(--color-white);
}
.weeklyTable__container.weekRatingTable table thead th:nth-child(6), 
.weeklyTable__container.weekRatingTable table thead th:nth-child(7), 
.weeklyTable__container.weekRatingTable table tbody tr td:nth-child(6), 
.weeklyTable__container.weekRatingTable table tbody tr td:nth-child(7){
    display: table-cell;
}
.weeklyTable__container.weekRatingTable table tbody tr td.rating__day{
    background-color: var(--color-gray4);
    padding: 0;
    border-bottom: 1px solid var(--color-greyish2);
    width: 1%;
    height: 67px;
    min-height: 68px;
    position: relative;
}
.weeklyTable__container.weekRatingTable table tbody tr td.rating__day, 
.weeklyTable__container.weekRatingTable table tbody tr td{
    font-size: 10px;
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: var(--color-slaty);
}
.weeklyTable__container.weekRatingTable table tbody tr td{
    padding: 6px 5px;
}
 /* .weeklyTable__container.weekRatingTable table tbody tr:nth-child(odd){
    background-color: var(--color-shadeSlaty);
} */
.weeklyTable__container.weekRatingTable table tbody tr td span{
    font-size: 12px;
    display: inline-flex;
    border-radius: 50%;
    /* width: 22px; */
    height: 22px;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    color: var(--color-slaty);
    padding: 3px;
}
.weeklyTable__container.weekRatingTable table tbody tr td span.rating__one{
    border: 1px solid var(--color-lightGray9);
    width: 22px;
    height: 22px;
    padding-top: 1px;
}
.weeklyTable__container.weekRatingTable table tbody tr td span.rating__five{
    border: 1px solid var(--color-fadeLightOrange);
    border-radius: 20px;
    font-weight: 700;
    padding: 3px 7px;
}
.weeklyTable__container.weekRatingTable table tbody tr td span.rating__seven{
    border: 1px solid var(--color-reddish);
    border-radius: 20px;
    font-weight: 700;
    padding:  3px 7px;
}
 .modified{
    background-color: rgb(232, 158, 173);
}
@media screen and (max-width:992px){
    .weeklyRating__table{
        border-left: 1px solid var(--color-greyish2);
        margin: 10px auto;
    }
}
.roted-box{
    transform: rotate(-90deg);
}
