
.foo{
    background-color: red;
}
.activenav{
    color:#2E67C7 !important;
    background: #eeeeef !important;
    font-weight: bold !important;
}
.sidebar-menu .ant-tooltip{
    left: 60px !important;
}
.ant-tooltip .ant-tooltip-inner, .ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content{
    background-color: var(--color-lightSlaty);
}
.ant-tooltip .ant-tooltip-inner{
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: white;
    padding: 12px 8px;
}
.ant-menu{
    background-color: var(--color-primary);
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background-color: var(--color-white);
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a p.menu__text{
    color: var(--color-primary);
}
.ant-menu-inline-collapsed.sidebar-menu.ant-menu-vertical p.m3p__fullForm{
    display: none;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.dashboard {
    background-image: url("../../assets/images/svg-icons/home-w.svg") !important;
    background-repeat: no-repeat !important;
    background-size: 24px 24px !important;
    background-position: 26px 24px !important;
  }
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.dashboard:hover,
 .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.dashboard{
    background-image: url('../../assets/images/svg-icons/home-b.svg') !important;
  background-repeat: no-repeat !important;
  background-size: 24px 24px !important;
  background-position: 26px 24px !important;
  background-color: #fff;
  border-radius: 0px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.management:hover,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.management{
    background-image: url("../../assets/images/svg-icons/patient_management-blue.svg") !important;
    background-repeat: no-repeat !important;
    background-size: 24px 24px !important;
    background-position: 26px 24px !important;
    background-color: #fff;
    border-radius: 0px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.management-dashboard:hover,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.management-dashboard{
    background-image: url("../../assets/images/svg-icons/patient_view_blue.svg") !important;
    background-repeat: no-repeat !important;
    background-size: 24px 24px !important;
    background-position: 26px 24px !important;
    background-color: #fff;
    border-radius: 0px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.management-add:hover,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.management-add{
    background-image: url("../../assets/images/svg-icons/add_patient_blue.svg") !important;
    background-repeat: no-repeat !important;
    background-size: 24px 24px !important;
    background-position: 26px 24px !important;
    background-color: #fff;
    border-radius: 0px;
   
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.add-patient:hover,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.add-patient{
    background-image: url("../../assets/images/svg-icons/add_user_blue.svg") !important;
    background-repeat: no-repeat !important;
    background-size: 24px 24px !important;
    background-position: 26px 24px !important;
    background-color: #fff;
    border-radius: 0px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.medication-setting:hover,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a.medication-setting{
    background-image: url("../../assets/images/svg-icons/settings_blue.svg") !important;
    background-repeat: no-repeat !important;
    background-size: 24px 24px !important;
    background-position: 26px 24px !important;
    background-color: #fff;
    border-radius: 0px;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border-right: 0;
}
// sidebar
.m3p__text{
    color: var(--color-white);
    text-align: center;
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 0.5px;
    display: inline-block;
}
.m3p__fullForm{
    display: inline-block;
    color: white;
    font-size: 10px;
    text-align: left;
    margin: 0;
    line-height: 12px;
    letter-spacing: 0.5px;
    padding-left: 5px;
}
.sidebar-brand .fa-bars{
    color: var(--color-white);
    cursor: pointer;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child), 
.ant-menu-vertical-right .ant-menu-item:not(:last-child), 
.ant-menu-inline .ant-menu-item:not(:last-child){
    margin-bottom: 0;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a p.menu__text,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a:hover p.menu__text, 
.ant-menu-item.ant-menu-item-only-child.dropdown.main-menu-item.ant-menu-item-active p.menu__text{
    color: var(--color-primary) !important;
}
.ant-menu-item.ant-menu-item-only-child.dropdown.main-menu-item.ant-menu-item-selected p.menu__text, 
.ant-menu-item.ant-menu-item-only-child.dropdown.main-menu-item.ant-menu-item p.menu__text{
    font-size: 14px;
    font-weight: 500;
    color: white;
    margin: 0;
    position: relative;
    top: -10px;
    vertical-align: middle;
    padding-left: 58px;
    letter-spacing: 0.5px;
    line-height: 16px;
    word-break: keep-all;
    white-space: break-spaces;
}

.sidebar-menu li a:hover p.menu__text, .sidebar-menu li a.active p.menu__text{
    color: var(--color-primary) !important;
}
.ant-menu-item.ant-tooptip-open.ant-menu-item-active.ant-menu-item-selected a p.menu__text{
    display: none !important;
}
.ant-menu-inline > .ant-menu-item, .ant-menu-vertical > .ant-menu-item{
    height: 75px;
    line-height: initial;
    padding: 0;
    padding-left: 0 !important;
}
.ant-tooltip-inner p.menu__text{
    margin-bottom: 0;
}
.ant-tooltip-inner .nav-link{
    padding: 0;
}
.ant-menu-inline .ant-menu-item-selected::after{
    border-right: 0;
}
.ant-layout-sider-trigger {
    position: absolute;
    bottom: 15px;
    z-index: 9999;
    left: 18px;
    height: 48px;
    color: #fff;
    line-height: 43px;
    text-align: center;
    background: #002140;
    cursor: pointer;
    transition: all 0.2s;
    width: 48px !important;
    border-radius: 50%;
    background-color: #2F98D7;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: var(--color-primary);
    overflow-x: hidden;
    transition: 0.1s;
    padding-top: 60px;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 20px;
    margin-left: 50px;
    color: var(--color-white);
  }
  

  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }
