.viewMed {
  width: 100%;
  margin: 2px auto;
  position: relative;
}
.viewMed__head {
  display: flex;
  /* grid-template-rows: 1fr; */
  /* grid-template-columns: 7fr 5fr; */
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
}
.heading.viewMed__heading {
  font-size: 14px;
}
.viewMed__rightContent {
  display: grid;
  align-items: flex-start;
  grid-template-columns: 4fr 3fr 3fr;
  justify-content: center;
  grid-template-rows: 1fr;
  gap: 10px;
  text-align: center;
}
.heading.viewMed__baseDesc {
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  margin: 0;
  line-height: 16px;
}
p.viewMed__subBaseDesc {
  font-weight: 700;
  display: inline-block;
  padding-left: 10px;
  margin: 0;
}
.viewMed__weekDays {
  display: grid;
  align-items: flex-start;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  gap: 10px;
}
.viewMed__weeks {
  display: grid;
  align-items: flex-start;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(6, 1fr);
  justify-content: center;
  gap: 10px;
}
.viewMed__weekContentWrap {
  /* background-color: var(--color-darkishBlue); */
  height: 100%;
  min-width: 180px;
}
.card_wrapper.viewMed__weekContent {
    /* background-color: transparent; */
    height: 85%;
    background-color: #EEF0F3;
    /* background-color: var(--color-gray6); */
}
.card_wrapper.viewMed__weekContent:hover,
.card_wrapper.viewMed__weekContent:focus,
.card_wrapper.viewMed__weekContent:active {
  border: 1px solid var(--color-darkishBlue);
}
.guardian_detail.baselineMed__style {
  margin: 20px auto;
}
.guardian_detail.baselineMed__style p {
  font-size: 14px;
  margin: 0;
}
.guardian_detail.weekMed__style p {
  font-size: 12px;
  margin: 0;
}
.viewMed__weekDosage {
  margin-bottom: 10px;
}
@media screen and (max-width: 1180px) {
  .heading.viewMed__heading,
  .heading.viewMed__baseDesc,
  p.viewMed__subBaseDesc,
  .guardian_detail h5 {
    font-size: 12px;
  }
  .guardian_detail.baselineMed__style p,
  .guardian_detail.weekMed__style p {
    font-size: 11px;
  }
  .viewMed__weeks {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 992px) {
  .viewMed__head {
    display: block;
  }
  .viewMed__rightContent {
    text-align: left;
    gap: 5px;
  }
  .viewMed__weekDays {
    grid-template-columns: repeat(3, 1fr);
  }
}
