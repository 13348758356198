.dashboard__wrap{
    width: 97%;
    margin: 35px auto;
    position: relative;
}
.card_wrapper.dashboard__content{
    background-color: var(--color-shadeSlaty);
    cursor: auto;
}
.card_wrapper.dashboard__content:hover, .card_wrapper.dashboard__content:focus, .card_wrapper.dashboard__content:active{
    border: 1px solid var(--color-shadeSlaty)
}
.dashboard__innerContent h3{
    font-size: 25px;
    color: var(--color-slaty);
}
.dashboard__innerContent h3.dashboard__username{
    margin-bottom: 20px;
}
.dashboard__innerContent p{
    font-size: 19px;
    color: var(--color-slaty);
    margin: 0;
}
.dashboard__nav{
    margin: 30px auto;
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    /* grid-template-columns: repeat(5, 1fr); */
    grid-template-columns: repeat(auto-fit, minmax(18%, 1fr));
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    height: 100%;
    min-height: 100%;
}
.dashboard__nav a{
    height: 100%;
    min-height: 100%;
}
.dashboard__links{
    background-color: var(--color-white);
    box-shadow: 0 2px 16px 0 rgb(0 0 0 / 10%);
    height: 100%;
    padding: 30px 10px;
    text-align: center;
    /* margin:  0 15px; */
    /* min-height: 100%; */
}
.dashboard__linkCard img{
    width: 45px;
}
.dashboard__linkCard h4{
    color: var(--color-slaty);
    font-size: 20px;
    margin-top: 30px;
    font-weight: 600;
}
.dashboard__linkCard p{
    color: rgba(65,79,99,0.6);
    font-size: 15px;
    line-height: 22px;
    letter-spacing: 0.3px;
}
.dashboard__arrow{
    color: var(--color-slaty);
    transform: rotate(180deg);
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
}
.dashboard__arrow:hover{
    color: var(--color-primary);
}
.learmore {
    text-align: center;
}
.learmore_message {
    font-size: 18px;
    text-align: center;
    color: var(--color-slaty);
    font-weight: bold;
    line-height: 30px;
    padding-top: 40px;
}
.learmore_link {
    font-size: 18px;
    text-align: center;
    color: var(--color-slaty);
    line-height: 30px;
    padding-top: 40px;
    color:#1890ff;
}
/* @media screen and (max-width:1500px){
    .dashboard__links{
        height: 40vh;
    }
} */
@media screen and (max-width:1150px) {
    .dashboard__linkCard img{
        width: 35px;
    }
    .dashboard__linkCard h4, .dashboard__innerContent p{
        font-size: 15px;
    }
    .dashboard__linkCard p{
        font-size: 13px;
    }
    .dashboard__innerContent h3{
        font-size: 20px;
    }
}
@media screen and (max-width:992px){
    .dashboard__nav{
        grid-template-columns: repeat(3, 1fr);
    }
}