.statusWrap{
    background-color: var(--color-skyBluish);
    border-radius: 5px;
    border-left: 5px solid var(--color-primary);
    padding: 12px 20px;
}
.statusWrap p{
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-slaty);
    letter-spacing: 0.4px;
}