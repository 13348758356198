.weekly__container{
    width: 100%;
    margin: 5px auto;
    position: relative;
}
.weeklyTable__container{
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: auto;
    /* height: 58vh; */
    min-height: calc(100vh - 386px);
    height: calc(100vh - 386px);
}
.weekly__visit_datepicker{
    width: 100%;
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.btn_add.ant-btn-default{
    float: right;
    height: 50px;
    border-radius: 5px;
    color: var(--color-primary);
    border-color: var(--color-primary);
    font-size: 14px;
    font-weight: 600;
    width: 180px;
}
.btn_add.ant-btn-default img{
    float: left;
}
.hideSearch_form{
    position: absolute;
    top: 34px;
    color: var(--color-slaty);
    cursor: pointer;
}
.ant-drawer-title{
    padding-left: 40px;
    font-weight: bold;
    color: var(--color-slaty);
    letter-spacing: 0.5px;
}
.weekly__tabs_wrapper{
    width: 88%;
    margin: 0 auto;
    position: relative;
}
.weekly__tabs{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.weekly__tabs .tabsWrapper{
    border-right: 0;
}
.tabContainer.weekly_tabsNote{
    border: 1px solid var(--color-greyish);
    border-radius: 5px;
    text-align: center;
    padding: 20px 5px;
    background-color: var(--color-greyShade);
}
.tabContainer.weekly_tabsNote.activeTab{
    background-color: var(--color-lightBlue);
    padding: 20px 10px;
}
.tabContainer.weekly_tabsNote .step-text {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tabContainer.weekly_tabsNote .step-text p span{
    color: #8692a1;
    font-size: 14px;
    font-weight: 600;
    
}
.tabContainer.weekly_tabsNote.activeTab .step-text p span{
    color: var(--color-slaty);
    font-weight: 600;
}
.tabContainer.weekly_tabsNote .step-text p{
    color: #8692a1;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    margin-top: 0;
}
.tabContainer.weekly_tabsNote.activeTab .step-text p{
    color: var(--color-slaty);
    font-weight: 500;
    padding-left: 0;
}
.week__content{
    width: 100%;
    margin: 30px auto;
    position: relative;
}
.week__form{
    margin: 30px auto;
    /* position: fixed;
    bottom: 0;
    width: 30%; */
}
.dosage_margin{
    margin-bottom: 11px;
}
.heading.weekHeading, .heading.weekHistoryHeading{
    position: relative;
    font-size: 16px;
    letter-spacing: 0.5px;
    font-weight: bold;
    margin: 0;
    line-height: 20px;
}
.heading.weekHistoryHeading{
    color: var(--color-primary);
}
.week__content p.ccfSubHeading{
    position: absolute;
    left: 18%;
    top: 2px;
    font-style: italic;
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 500;
}
.textareaNote{
    height: 180px !important;
    width: 100%;
    padding: 18px 12px !important;
}
.textareaNote::placeholder{
    font-weight: 500;
}

.weeklyTable__container table {
    position: relative;
    border-collapse: collapse;
    width: 100%;
}
  
.weeklyTable__container th, .weeklyTable__container td {
    width: 20%;
}

.weeklyTable__container tr.side__effects th, .weeklyTable__container tr.history__sympt th, .weeklyTable__container tr.target th{
    height: 55px;
    padding: 0 20px;
    font-size: 16px;
    box-shadow: 0 0 0 0;
    border-right: 1px solid var(--color-greyish);
}
.weeklyTable__container tr.side__effects th {
    background: var(--color-lightGrey);
    color: var(--color-slaty);
    z-index: 1;
    padding-left: 50px;
}
.weeklyTable__container tr.side__effects th:last-child{
    border-right: 0;
}
.weeklyTable__container tr.history__sympt th, tr.target th {
    background: var(--color-lightGrey);
    color: var(--color-primary);
}
.weeklyTable__container tr.history__sympt th{
    z-index: 2;
}
.weeklyTable__container tr.target th {
    z-index: 3;
}
.weeklyTable__container th {
    background: white;
    position: sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}
.weeklyTable__container table tbody tr:first-child{
    border-top: 1px solid var(--color-greyish1);
}
.weeklyTable__container td{
    border-right: 1px solid var(--color-greyish);
    border-bottom: 1px solid var(--color-greyish);
    padding: 0;
    position: relative;
}
.weeklyTable__container table thead th:first-child,
.weeklyTable__container table thead th:nth-child(2), 
.weeklyTable__container table tbody tr td:first-child,
.weeklyTable__container table tbody tr td:nth-child(2){
    position: sticky;
}
.weeklyTable__container table thead th:nth-child(6),
.weeklyTable__container table thead th:nth-child(7),
.weeklyTable__container table tbody tr td:nth-child(6),
.weeklyTable__container table tbody tr td:nth-child(7){
    display: none;
}
/* .weeklyTable__container table thead:hover ~ .week__btn.sliderLeft, 
.weeklyTable__container table thead:hover ~ .week__btn.sliderBtn{
   display: inline-grid;
    z-index: 200;
    transition: opacity 8s ease-in-out;
    cursor: pointer;
}
.week__btn.sliderLeft, 
.week__btn.sliderBtn{
  display: none;
} */

.selected__dot{
    position: absolute;
    border-radius: 50%;
    height: 10px;
    width: 10px;
    background-color: var(--color-eucalyptus);
    right: 10px;
}
.future__weeks .month__name, .future__weeks .month__date{
    font-style: normal;
}
.future__weeks .month__name{
    font-size: 16px;
    font-weight: bold;
}
.future__weeks .month__date{
    font-size: 30px;
    line-height: 35px;
}
.heading.weekwise__head{
    font-size: 14px;
    margin: 0;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0.4px;
}
p.week__subHead{
    font-size: 12px;
    font-weight: 500;
    margin: 0;
}
p.week__subCol{
    font-size: 14px;
    font-weight: 500;
    color: var(--color-slaty);
    padding: 0 20px;
}
.empty{
    background-color: var(--color-grey);
    border-right: 0;
}
.rank {
    font-size: 20px;
    letter-spacing: 5px;
    font-weight: 700;
    text-align: center;
    color: var(--color-slaty);
    margin-bottom: 0em;
}
.RFI_btn{
    padding: 20px 0;
    width: 75%;
    margin: 0 auto;
}
.teacherRFI_btn{
    padding: 20px 10px;
    width: 100%;
    margin: 0 auto;
}
.heading.weekCGIHeading{
    padding: 20px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    position: relative;
}
.heading.weekMedHeading{
    padding: 20px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
}
p.weekCGIsubHeading{
    position: absolute;
    top: 27px;
    left: 47px;
}
/* .weeklyTable__container .ant-popover{
    z-index: 0;
} */
.weekSev__edit{
    position: relative;
    top: 0;
    right: 0;
    padding-left: 6px;
}
.weekSev__editTeacher{
    position: relative;
    top: 0;
    right: 0;
    padding-left: 3px;
}

.weekSev__edit img, .weekSev__editTeacher img{
    width: 16px;
}
.weeklyTable__container .ant-popover-arrow{
    display: none;
}

.weeklyTable__container .ant-popover-content{
    width: 235px;
    border: 1px solid var(--color-grey1);
    box-shadow: 1px 1px 15px 0 rgba(0,0,0,0.1);
    border-radius: 5px;
    background-color: var(--color-white);
    position: relative;
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
    height: 100%;
    opacity: 0.6;
    background: var(--color-white);
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper{
    box-shadow: -3px 2px 15px 0 rgba(0,0,0,0.1);
}
/* .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper .ant-drawer-wrapper-body{
    margin-bottom: 10px;
} */
.ant-drawer-header{
    border-bottom: 0;
    padding: 30px 24px;
}
.future__weeks{
    width: 80%;
    margin: 0 auto;
    position: relative;
    padding: 20px;
    background-color: var(--color-shadeGray);
    border: 1px solid var(--color-shadeGray);
    border-radius: 5px;
    font-style: italic;
    font-size: 12px;
    color: var(--color-slaty);
    text-align: center;
    position: absolute;
    top: 95px;
    left: 0;
    right: 0;
}
.scrollTop.ant-btn-primary {
    position: fixed; 
    width: 40px;
    bottom: 30px;
    right: 75px;
    align-items: center;
    height: 44px;
    border-radius: 4px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5;
    border: 1px solid var(--color-primary);
    background-color: var(--color-lightShimmerBlue);
}
.scrollTop.ant-btn-primary:hover{
    background-color: var(--color-primary);
}
.scrollTop.ant-btn-primary:hover .arrow_btn{
    color: white;
}
.arrow_btn{
    font-size: 20px;
    color: var(--color-primary);
    font-weight: bolder;
    vertical-align: middle;
    position: relative;
    top: -2px;
}
.scrollTop:hover{
    opacity: 1;
}
  
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}
.disabledbutton{
    pointer-events: none;
    opacity: 0.4;
}
.enabledbutton{
    pointer-events: auto;
    opacity: 1;
}
.weekly__msg{
    font-size: 18px;
    text-align: center;
    color: var(--color-slaty);
    font-weight: bold;
    line-height: 30px;
}
.teacherRFI_btn .btn-select .btn-addon .btn:disabled:hover{
    border: 0.7px solid #d7dde1;
    border-right-width: 1px;
    background-color:transparent;
    opacity: 0.65;
}
.rank-help-text {
    font-size: 12px;
    text-align: center;
    color: var(--color-slaty);
    padding: 7px 12px;
    letter-spacing: 0.5px;
    font-weight: 500;
}
.remove-cursor{
    cursor:default !important;
}
.add-cursor {
    cursor: pointer !important;
}
@media screen and (max-width:1700px){
    .heading.weekMedHeading, p.week__subCol{
        font-size: 12px;
        padding: 20px 7px;
    }
    .teacherRFI_btn .btn-select .btn-addon .btn{
        min-width: 70px;
        padding: 5px 7px;
        font-size: 12px;
    }
}
@media screen and (max-width: 1600px){
    .RFI_btn .btn-select .btn-addon .btn{
        min-width: 65px;
    }
}
@media screen and (max-width: 1420px){
    p.week__subCol, tr.side__effects th, tr.history__sympt th, tr.target th{
        padding: 0 10px;
    }
}
@media screen and (max-width: 1320px){
    .heading.weekwise__head{
        font-size: 12px;
    }
    .heading.weekHeading, .heading.weekHistoryHeading{
        font-size: 14px;
    }
}
@media screen and (max-width:1180px){
    .weeklyTable__container tr.side__effects th{
        padding-left: 35px;
    }
}
@media screen and (max-width: 1100px){
    .RFI_btn{
        width: 92%;
    }
}
@media screen and (max-width:992px){
    .future__weeks{
        padding:5px;
        left: 10px;  
        font-size: 10px;  
    }
    .future__weeks .month__name{
        font-size: 13px;
    }
    .future__weeks .month__date{
        font-size: 25px;
    }
}
