.week__btn{
    width: 30px;
    height: 30px;
    display: grid;
    border: 1px solid var(--color-primary);
    box-shadow: 2px 4px 30px 0 rgba(0,0,0,0.1);
    /* grid-template-rows: 1fr; */
    /* grid-template-columns: 3fr 9fr; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    z-index: 99;
    top: 11px;
    /* margin-top: 8px; */
    border-radius: 50%;
}

.btn_icon{
    color: var(--color-primary);
    font-size: 14px;
}
.week__btn p{
    display: inline-block;
    margin: 0;
    border-left: 1px solid var(--color-primary);
    text-align: center;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    color: var(--color-slaty);
    background-color: var(--color-lightBlue);
}
.week__btn.sliderBtn p, .week__btn.week__btn.sliderRight p{
    border-left: 0;
    border-right: 1px solid var(--color-primary);
}
.week__btn.sliderLeft{
    left: 2.5%;
    /* left: 45.5%; */
}
.week__btn.sliderLeftBtn{
    left: 6px;
}
.week__btn.sliderBtn{
    /* grid-template-columns: 9fr 3fr; */
    right: 18px;
}

.week__btn.sliderBtn .btn_icon, .week__btn.sliderRight .btn_icon{
    order: 2;
    transform: rotate(180deg);
}
.week__btn.sliderRight{
    right: 92px;
}

@media screen and (max-width: 1180px){
    .week__btn {
        width: 24px;
        height: 26px;
    }
    .week__btn.sliderBtn{
        left: 5px;
    }
}
@media screen and (max-width: 992px){
    .week__btn.sliderLeft {
        left: 4px;
    }
}
