.list-container{
  .tabsWrapper{
    border-right: 0;
    width: 36px;
  }
}
.tabContainer.listFilterTab{
  padding: 0;
  .step-text{
    img{
      background-color: var(--color-shadeSlaty);
      border: 1px solid var(--color-lightGray10);
      border-radius: 5px;
      padding: 9px;
      width: 36px;
      min-width: 36px;
    }
    p{
      display: none;
    }
  }
}
.tabContainer.listFilterTab.activeTab{
  background-color: transparent;
  .step-text{
    grid-template-columns: 1fr;
    padding: 0;
   
    img{
      border: 1px solid var(--color-primary);
    }
  }
}
.listing__filters{
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  gap: 7px;
}
.acc__status{
  font-size: 11px;
  padding: 3px 6px;
  color: var(--color-navyBlue);
  border: 1px solid var(--color-shadeOrange);
  border-radius: 13px;
  margin-top: 2px;
  font-weight: 500;
}
.acc__invited{
  border: 1px solid var(--color-primary);
}
.acc__activated{
  border: 1px solid var(--color-shadeGreen);
}
.acc__deactivated{
  border: 1px solid var(--color-shadeBrown);
}
.user-list {
  list-style-type: none;
  padding-left: 0;
  // margin-top: 20px;

  .user-list-item {
    margin-bottom: 5px;

    .user-link {
      display: flex;
      align-items: flex-start;
      justify-content: left;
      border-left: 5px solid var(--color-shadeNavyBlue);
      // border-radius: 2px;
      padding: 10px 10px;
      color: #414f63;
      background-color: var(--color-skyBluish);
      position: relative;

      &:hover {
        text-decoration: none;
        background-color: var(--color-white);
        // border: 1px solid var(--color-white);
        border-left: 5px solid var(--color-primary);
        border-top: 0;
        border-right: 0;
        border-bottom: 0;
      }
     
      &.active {
        background-color: var(--color-white);
        // border: 1px solid var(--color-white);
        border-left: 5px solid var(--color-primary);
        border-top: 0;
        border-right: 0;
        border-bottom: 0;
      }
      // &::before{
      //   content: '';
      //   position: absolute;
      //   border-left: 5px solid transparent;
      //   left: 0;
      //   height: 103px;
      //   top: -1px;
      // }
      // &.active::before, &:hover::before{
      //   content: '';
      //   position: absolute;
      //   border-left: 5px solid var(--color-primary);
      //   left: 0;
      //   height: 103px;
      //   top: -1px;
      // }
      .user-icon {
        width: 35px;
        height: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin-right: 10px;
        background-color: #dedab7;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0.5px;
        color: var(--color-slaty);

        &:nth-child(even) {
          background-color: #d3dee8;
        }
      }

      .name-role {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        color: var(--color-slaty);

        .name {
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 2px;
        }
        .mrNo{
          font-size: 12px;
          line-height: 15px;
        }
        .role {
          font-weight: 400;
          font-size: 12px;
          width: 130px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 15px;
        }
      }
    }
    .user-link.link__style::before, .user-link.link__style.active::before, .user-link.link__style:hover:before{
      height: 61px;
    }

   

    &:nth-child(odd) {
      .user-link {
        .user-icon {
          background-color: #d3dee8;
        }
      }
    }
  }
}
.list-sel{
  background-color: var(--color-skyBluish);
}
.add__icon.anticon-plus{
  color: var(--color-white);
  background-color: var(--color-primary);
  padding: 8px;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  top: 0;
}

.style  {
  height: 30;
  border: "1px solid green";
  margin: 6;
  padding: 8;
};

@media screen and (max-width: 1500px) {
  .user-list .user-list-item .user-link .name-role .role {
    width: 100px;
  }
}

@media screen and (max-width:1195px){
  .user-list .user-list-item .user-link .name-role .name,
  .user-list .user-list-item .user-link .name-role .mrNo, 
  .user-list .user-list-item .user-link .name-role .role,
  .user-list .user-list-item .user-link .user-icon{
    font-size: 11px;
  }
}
@media screen and (max-width:1180px){
  .user-list .user-list-item .user-link{
    padding: 10px 0 10px 8px;
  }
}

@media screen and (max-width:850px){
  .user-list .user-list-item .user-link .name-role .name, 
  .user-list .user-list-item .user-link .name-role .mrNo, 
  .user-list .user-list-item .user-link .name-role .role, 
  .user-list .user-list-item .user-link .user-icon{
    font-size: 10px;
  }
  .user-list .user-list-item .user-link .user-icon{
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  .listing__filters{
    gap: 4px;
  }
  .tabContainer.listFilterTab .step-text img{
    width: 30px;
    min-width: 30px;
    padding: 7px;
  }
  .add__icon.anticon-plus{
    padding: 5px;
  }
}