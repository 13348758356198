.aside-menu {
  padding-top: 20px;
  padding-right: 0;
  background-color: var(--color-shadeSlaty);

  .registration {
    .add-user-btn {
      background-color: #0078bf;
      margin-bottom: 20px;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      > img {
        margin-right: 6px;
      }
    }
  }
  
  .search-user {
    border: 1px solid #d8d8d8;
    border-radius: 20px;
  }

  .user-list {
    list-style-type: none;
    padding-left: 0;
    // margin-top: 20px;

    .user-list-item {
      margin-bottom: 5px;

      .user-link {
        display: flex;
        align-items: flex-start;
        justify-content: left;
        border-left: 5px solid var(--color-shadeNavyBlue);
        // border-radius: 2px;
        padding: 10px 10px;
        color: #414f63;
        background-color: var(--color-skyBluish);
        position: relative;

        &:hover {
          text-decoration: none;
          background-color: var(--color-white);
          // border: 1px solid var(--color-white);
          border-left: 5px solid var(--color-primary);
          border-top: 0;
          border-right: 0;
          border-bottom: 0;
        }
       
        &.active {
          background-color: var(--color-white);
          // border: 1px solid var(--color-white);
          border-left: 5px solid var(--color-primary);
          border-top: 0;
          border-right: 0;
          border-bottom: 0;
        }
        // &::before{
        //   content: '';
        //   position: absolute;
        //   border-left: 5px solid transparent;
        //   left: 0;
        //   height: 103px;
        //   top: -1px;
        // }
        // &.active::before, &:hover::before{
        //   content: '';
        //   position: absolute;
        //   border-left: 5px solid var(--color-primary);
        //   left: 0;
        //   height: 103px;
        //   top: -1px;
        // }
        .user-icon {
          width: 35px;
          height: 35px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          margin-right: 10px;
          background-color: #dedab7;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 0.5px;
          color: var(--color-slaty);

          &:nth-child(even) {
            background-color: #d3dee8;
          }
        }

        .name-role {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          color: var(--color-slaty);

          .name {
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 2px;
          }
          .mrNo{
            font-size: 12px;
            line-height: 15px;
          }

          .role {
            font-weight: 400;
            font-size: 12px;
            width: 130px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 15px;
          }
        }
      }

      // .user-link.user_height{
      //   &::before{
      //     content: '';
      //     position: absolute;
      //     border-left: 5px solid transparent;
      //     left: 0;
      //     height: 61px;
      //     top: -1px;
      //   }
      //   &.active::before, &:hover::before{
      //     content: '';
      //     position: absolute;
      //     border-left: 5px solid var(--color-primary);
      //     left: 0;
      //     height: 61px;
      //     top: -1px;
      //   }
      // }

      &:nth-child(even) {
        .user-link {
          .user-icon {
            background-color: #d3dee8;
          }
        }
      }
    }
  }
}
.add-user{
  padding-right: 15px;
}
.main-section {
    padding-top: 20px;

    .header-section {
        display: flex;
        align-items: center;
        padding-bottom: 30px;

        .head-title {
            h3 {
                margin-bottom: 0;
            }
        }
    }

    .search-box {
        padding: 0;

        .search-bar {
            height: 55px;
            border-radius: 1px solid #b5c3cf;
            border-radius: 30px;
        }
    }

    .notification {
        text-align: center;
        position: relative;
        max-width: 45px;
        margin: auto;

        .items {
            width: 23px;
            height: 23px;
            border-radius: 50%;
            position: absolute;
            right: 0;
            background-color: #f53610;
            color: #ffff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }
    }

    .profile {
        display: flex;
    }

    .user-role {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        font-weight: 500;

        .name {
            font-size: 14px;
            color: #414f63;
        }

        .role {
            font-size: 12px;
            color: #8692a1;
        }
    }

    .info-section {
        .info-title {
            margin-bottom: 20px;

            h3 {
                font-size: 18px;
                font-weight: 600;
                color: #3d3d3d;
            }
        }

        .form-group {
            border-radius: 5px;
            width: 100%;
            position: relative;
            margin-bottom: 38px;

            .form-label {
                position: absolute;
                top: -9px;
                left: 15px;
                font-size: 12px;
                color: #414f63;
                margin-bottom: 0;
                background-color: #fff;
                padding: 0px 2px;
            }

            .form-control {
                height: 56px;
                margin-top: 0;
                border: 0.7px solid #6b798e;
            }

            &.user-name {
                .form-control {
                    height: 56px;
                    margin-top: 0;
                    border: 0.7px solid #9ca6b3;
                }
            }
        }
    }

    .form-control[readonly],
    .form-control:disabled {
        background-color: #f4f4f4;
    }

    .send-invite {
        display: flex;
        flex-wrap: nowrap;

        .invite-input {
            border: 0.7px solid #9ca6b3 !important;
            border-right: 0 !important;
            border-radius: 5px 0 0 5px;
        }

        .invite-btn {
            width: 180px;
            height: 56px;
            border-radius: 0 5px 5px 0;
            border: 0.7px solid #9ca6b3;
            background-color: #e0f0fa;
        }
    }

    .btn-box {
        text-align: right;

        .btn-save {
            height: 55px;
            min-width: 139px;
            color: #0078bf;
            width: 150px;
            font-weight: 500;
            margin-left: 20px;
            background-color: #e0f0fa;
        }

        .invite-button {
            color: #ffffff;
            background-color: #0078bf;
        }
    }
}

.role-color {
  color: #e34332;
  position: relative;
  top: -15px;
  font-size: 12px;
}
.role-error {
  display: block;
  margin-bottom: 38px;
}

@media screen and (max-width: 1500px) {
  .user-list .user-list-item .user-link .name-role .role {
    width: 100px;
  }
}
@media screen and (max-width: 1450px){
  .aside-menu.col-md-2{
    max-width: 20%;
    flex: 0 0 20%;
  }
  .main-section.col-md-10{
    max-width: 80%;
    flex: 0 0 80%;
  }
}
@media screen and (max-width:1195px){
  .aside-menu .user-list .user-list-item .user-link .name-role .name,
  .aside-menu .user-list .user-list-item .user-link .name-role .mrNo, 
  .aside-menu .user-list .user-list-item .user-link .name-role .role,
  .aside-menu .user-list .user-list-item .user-link .user-icon{
    font-size: 11px;
  }
}
@media screen and (max-width:1180px){
  .aside-menu.col-md-2{
    padding-left: 5px;
  }
  .aside-menu .user-list .user-list-item .user-link{
    padding: 10px 0 10px 8px;
  }
  .info-section .col-md-3{
    max-width: 40%;
    flex: 0 0 40%;
  }
  .info-section .col-md-6{
    max-width: 80%;
    flex: 0 0 80%;
  }
  .aside-menu.col-md-2 {
    max-width: 24%;
    flex: 0 0 24%;
  }
  .main-section.col-md-10 {
    max-width: 76%;
    flex: 0 0 76%;
}
}

@media screen and (max-width:992px){
  .aside-menu.col-md-2 {
    max-width: 28%;
    flex: 0 0 28%;
  }
  .main-section.col-md-10{
    max-width: 72%;
    flex: 0 0 72%;
  }
  .info-section .col-md-3 {
    max-width: 50%;
    flex: 0 0 50%;
  }
  .info-section .col-md-6 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
