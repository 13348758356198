body {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  margin: 0;
}

#container {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  width: 800px;
  height: 800px;
  background-color: #fff;
}

.finalReport {
  width: 100%;
  margin: 0 auto;
  position: relative;
  border: 1px solid var(--color-gray4);
  padding: 25px;
}
.final__header {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 3fr 6fr 3fr;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.finalReport__name {
  text-align: center;
}
.final__logo {
  /* margin: 40px auto; */
  width: 100%;
}
.finalReport__participants {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 5px;
  gap: 5px;
  grid-column: 1 / span 2;
  width: 85%;
}
.finalReport__participants .medical-group.multi-roles {
  width: 100%;
}
.final__logo h3 {
  font-size: 40px;
  font-weight: 700;
  color: var(--color-primary);
  margin: 0;
  padding-right: 7px;
  display: inline-block;
}
.final__logo p {
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  color: var(--color-primary);
  margin: 0;
  display: inline-block;
}
.final__logo h4 {
  font-size: 16px;
  color: var(--color-slaty);
  letter-spacing: 0.5px;
}
.final__rghtContent p.final__ADHD {
  font-size: 14px;
  font-style: italic;
  color: var(--color-slaty);
}
.final__medTrial,
.final__medResponse {
  color: var(--color-slaty);
  font-weight: 500;
  padding: 10px 20px;
}
.final__medResponse {
  font-size: 13px;
}
pre.final__medResponse {
  word-break: keep-all;
  white-space: pre-wrap;
  font-family: "Raleway", sans-serif !important;
  color: var(--color-slaty);
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.final__medTrial {
  border-top: 5px solid var(--color-gray5);
  border-bottom: 5px solid var(--color-gray5);
  margin: 20px auto;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.finalReport__basicInfo {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  border-bottom: 1px solid var(--color-gray5);
  padding-bottom: 20px;
  width: 97%;
  margin: 20px auto 0;
}
.finalReport__medication {
  width: 97%;
  margin: 12px auto;
  position: relative;
}
.margin-leftP {
  width: 100%;
  align-items: center;
  margin-left: 200px;
  margin-top: 40px;
}
.margin-leftPNew {
  width: 100%;
  align-items: center;
  margin-left: 0;
  margin-top: 40px;
}
.margin-leftPNew .recharts-wrapper{
  margin: 0px auto;
}
.finalReport__medication .card_wrapper.viewMed__weekContent {
  background-color: var(--color-gray6);
}
.finalReport__medication .card_wrapper.viewMed__weekContent:hover,
.finalReport__medication .card_wrapper.viewMed__weekContent:focus,
.finalReport__medication .card_wrapper.viewMed__weekContent:active {
  border: 1px solid var(--color-gray6);
}
.weeklyTable__container.weekRatingTable.finalReport__table {
  height: auto;
  min-height: auto;
  margin: 20px auto;
}
.weeklyTable__container.finalReport__table td:last-child {
  border-right: 0;
}
.weeklyTable__container.weekRatingTable.finalReport__table table tbody tr {
  background-color: var(--color-white);
}
.weeklyTable__container.weekRatingTable.finalReport__table table tbody tr td {
  padding: 10px 5px;
}
p.finalReport__subHead {
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  margin: 0;
}
.final__sevr {
  font-size: 11px;
  font-weight: 500;
  color: var(--color-navyBlue);
  margin: 0;
}
.final__sevrName {
  font-weight: 600;
  color: var(--color-slaty);
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 16px;
  margin: 0;
}
.weeklyTable__container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  overflow: auto;
  /* height: 58vh; */
  min-height: calc(100vh - 422px);
  height: calc(100vh - 422px);
}
.weeklyTable__container.weekRatingTable {
  height: 100%;
  min-height: 100%;
}
.weeklyTable__container.weekRatingTable.finalReport__table
  table
  tbody
  tr
  td
  span.dot {
  border-radius: 50%;
  display: inline-block;
}
.weeklyTable__container.weekRatingTable.finalReport__table
  table
  tbody
  tr
  td
  span.dot.weekOrange,
.weeklyTable__container.weekRatingTable.finalReport__table
  table
  tbody
  tr
  td
  span.dot.weekExtremeDot {
  height: 10px;
  width: 10px;
  margin-right: 6px;
}
.weeklyTable__container.weekRatingTable.finalReport__table
  table
  tbody
  tr
  td
  span.dot.weekOrange {
  background-color: var(--color-fadeLightOrange);
}
.weeklyTable__container.weekRatingTable.finalReport__table
  table
  tbody
  tr
  td
  span.dot.weekExtremeDot {
  background-color: var(--color-reddish);
}
.weeklyTable__container.weekRatingTable.finalReport__table table tr td ul {
  list-style: none;
  padding: 20px;
  text-align: left;
}
.weeklyTable__container.weekRatingTable.finalReport__table table tr td ul li {
  font-size: 12px;
  font-weight: 500;
  padding-bottom: 7px;
  letter-spacing: 0.5px;
  color: var(--color-slaty);
}
.final__mainTD {
  background-color: var(--color-lightGrey);
  width: 5% !important;
}
.final__mainCGLSevr {
  background-color: var(--color-lightGrey);
  width: 9.5% !important;
}
.weeklyTable__container.weekRatingTable.finalReport__table
  tr.side__effects
  th:first-child {
  width: 13.4%;
}
.final__cgiSevr {
  border: 1px solid transparent;
  border-radius: 20px;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  padding: 5px 5px;
}
.final__cgiSevr .final__sevrName {
  font-size: 12px;
}
.final__cgiSevr.finalServr__extreme {
  border: 1px solid var(--color-reddish);
}
.final__cgiSevr.finalServr__orange {
  border: 1px solid var(--color-fadeLightOrange);
}
.weeklyTable__container.weekRatingTable.finalReport__table tr.side__effects th {
  background-color: var(--color-lightGrey);
  text-align: center;
}
.weeklyTable__container.weekRatingTable.finalReport__table td {
  width: 10%;
}

.finalReport_dwnload {
  width: 165px;
  height: 56px;
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-right: 10px;
}
.finalReport_dwnload img {
  margin-right: 10px;
}
.report__summary {
  border: 1px solid var(--color-dimGrey);
  width: 97%;
  margin: 4px auto;
  border-radius: 2px;
}
p.finalReport__subHead {
  font-weight: 700;
  display: inline-block;
  margin: 0;
  padding-left: 1.5%;
  font-size: 14px;
}
.final__medResponse .multi-roles .reactSelect div[class$="control"],
.finalReport__participants .multi-roles .reactSelect div[class$="control"] {
  background-color: transparent;
  height: auto;
  min-height: 40px;
  margin-top: 0;
  border: 0.7px solid var(--color-gray7);
  background-color: var(--color-skyBluish);
  color: var(--color-slaty);
}
.final__medResponse .multi-roles .reactSelect div[class$="menu"] {
  z-index: 10;
}
.role-disable {
  background-color: #f5f5f5;
  opacity: 1;
}
.weekly__msg {
  font-size: 18px;
  text-align: center;
  color: var(--color-slaty);
  font-weight: bold;
  line-height: 30px;
}
#scrollWrap{
    overflow-x:hidden
}
.DraftEditor-root{
  min-height: 200px;
  padding: 10px;
}
@media screen and (max-width: 1750px) {
    .finalReport {
    padding: 25px 15px;
  }
}
@media screen and (max-width: 1580px) {
  .final__header {
    grid-template-columns: 4fr 6fr 4fr;
  }
}
@media screen and (max-width: 1280px) {
  .heading.viewMed__baseDesc,
  p.viewMed__subBaseDesc,
  .final__rghtContent p.final__ADHD {
    font-size: 11px;
  }
  .final__logo h4 {
    font-size: 13px;
  }
}

@media screen and (max-width: 1180px) {
  p.finalReport__subHead,
  .heading.rating__heading {
    font-size: 11px;
  }
  .final__cgiSevr .final__sevrName,
  .weeklyTable__container.weekRatingTable.finalReport__table table tr td ul li {
    font-size: 10px;
  }
  .final__sevrName {
    font-size: 12px;
  }
  .finalReport__basicInfo {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 1067px) {
  .final__header {
    grid-template-columns: 4fr 2fr 4fr;
  }
  .finalReport {
    padding: 25px 0;
  }
}
@media screen and (max-width: 992px) {
  .finalReport {
    padding: 15px 10px;
  }
  .heading.viewMed__baseDesc,
  p.viewMed__subBaseDesc,
  .final__rghtContent p.final__ADHD {
    font-size: 10px;
  }
  .final__header {
    gap: 0;
  }
}
