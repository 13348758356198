.scroll-box{
  
  padding-right: 15px;
}

.card-view{
    height: 110px;
    width: 100%;
    border: 1px solid #DBDEEA;
    background-color: #F4F4F4;
    padding: 25px 10px 25px 40px;
    cursor: pointer;
    h4{
        font-size: 16px;
        margin-bottom: 0px;
        color: #414F63;
        margin-top: 5px;
    }
    p{
        color: #414F63;
        font-size: 12px;
        font-weight: 300;
    }
    &.active{
        background: #ffff;
        border-left: 6px solid #0078BF;
        position: relative;
        right: -1px;
        border-right-color: #fff;
        z-index: 1;

    }
}
.check-radios{
    margin-top: 10px;
    .btn-light{
        font-size: 14px;
        font-weight: 500;
        background-color: #fff !important;
        border-color: #D8D8D8 !important;
        padding: 13px 9px;
        width: 105px;
        &.active{
            background-color: #E0F0FA  !important;
            border-color: #E0F0FA !important;
        }
        &.days-list{
          max-width: 80px;
        }
    }
}
.check-radios input{
  display: none;
}
.gray-box{
    min-height: 127px;
    width: 100%;
    border: 1px solid #D8D8D8;
    border-radius: 5px;
    background-color: #FBFBFB;
    padding: 10px;
    small{
        color: #414F63;
        font-size: 12px;
        line-height: 14px;
    }
    strong{
        font-size: 14px;
        font-weight: 500;
        color: #414F63;
    }
}
.titel-box{
    font-size: 14px;
    line-height: 16px;
    color: #414f63;
    text-align: left;
    letter-spacing: 0.3px;
    margin-bottom: 5px;
}
.gray-box-item {
    background: #eceef1;
    width: 100%;
    height: calc(100% - 30px);
    min-height: 220px;
    padding: 15px;
    border-radius: 5px;
    background-color: #EEF0F3;

    .no-data {
        font-size: 14px;
        color: #878c91;
        font-weight: 400;
        margin-bottom: 1px;
        margin-top: 15px;
    }

    h6 {
        font-size: 14px;
        color: #414f63;
        font-weight: 600;
        margin: 0;
        letter-spacing: 0.5px;
        margin-bottom: 2px;
        word-break: break-word;
    }
}
.final__medTrial {
  border-top: 5px solid var(--color-gray5);
  border-bottom: 5px solid var(--color-gray5);
  margin: 20px auto;
  font-size: 14px;
  letter-spacing: 0.5px;
}
.section-titel{
    font-weight: 700;
    font-size: 14px;
    color: #414f63;
    text-transform: uppercase;
}
.multi-select{
    margin-top: 11px;
    .css-yk16xz-control{
        height: 50px;
        border-color: #6b798e;
        
        &:hover{
            border-color: #6b798e;
        }
        &:focus{
            border-color: #6b798e;
            box-shadow: none;
        }
    }
    .css-1pahdxg-control{
      height: 50px;
      border-color: #6b798e;
     
      &:hover{
          border-color: #6b798e;
      }
      &:focus{
          border-color: #6b798e;
          box-shadow: none;
      }
    }
    .css-2613qy-menu{
        z-index: 999;
    }
}

.weeklyTable__container.weekRatingTable.finalReport__table {
    height: auto;
    min-height: auto;
    margin: 20px auto;
  }
  .weeklyTable__container.finalReport__table td:last-child {
    border-right: 0;
  }
  .weeklyTable__container.weekRatingTable.finalReport__table table tbody tr {
    background-color: var(--color-white);
  }
  .weeklyTable__container.weekRatingTable.finalReport__table table tbody tr td {
    padding: 10px 5px;
  }
  .weeklyTable__container {
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: auto;
    /* height: 58vh; */
    min-height: calc(100vh - 422px);
    height: calc(100vh - 422px);
  }
  .weeklyTable__container.weekRatingTable {
    height: 100%;
    min-height: 100%;
  }
  .weeklyTable__container.weekRatingTable.finalReport__table
    table
    tbody
    tr
    td
    span.dot {
    border-radius: 50%;
    display: inline-block;
  }
  .weeklyTable__container.weekRatingTable.finalReport__table
    table
    tbody
    tr
    td
    span.dot.weekOrange,
  .weeklyTable__container.weekRatingTable.finalReport__table
    table
    tbody
    tr
    td
    span.dot.weekExtremeDot {
    height: 10px;
    width: 10px;
    margin-right: 6px;
  }
  .weeklyTable__container.weekRatingTable.finalReport__table
    table
    tbody
    tr
    td
    span.dot.weekOrange {
    background-color: var(--color-fadeLightOrange);
  }
  .weeklyTable__container.weekRatingTable.finalReport__table
    table
    tbody
    tr
    td
    span.dot.weekExtremeDot {
    background-color: var(--color-reddish);
  }
  .weeklyTable__container.weekRatingTable.finalReport__table table tr td ul {
    list-style: none;
    padding: 20px;
    text-align: left;
  }
  .weeklyTable__container.weekRatingTable.finalReport__table table tr td ul li {
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 7px;
    letter-spacing: 0.5px;
    color: var(--color-slaty);
  }
  .final__mainTD {
    background-color: var(--color-lightGrey);
    width: 5% !important;
  }
  .final__mainCGLSevr {
    background-color: var(--color-lightGrey);
    width: 9.5% !important;
  }
  .weeklyTable__container.weekRatingTable.finalReport__table
    tr.side__effects
    th:first-child {
    width: 13.4%;
  }
  .final__cgiSevr {
    border: 1px solid transparent;
    border-radius: 20px;
    width: fit-content;
    text-align: center;
    margin: 0 auto;
    padding: 5px 5px;
  }
  .final__cgiSevr .final__sevrName {
    font-size: 12px;
  }
  .final__cgiSevr.finalServr__extreme {
    border: 1px solid var(--color-reddish);
  }
  .final__cgiSevr.finalServr__orange {
    border: 1px solid var(--color-fadeLightOrange);
  }
  .weeklyTable__container.weekRatingTable.finalReport__table tr.side__effects th {
    background-color: var(--color-lightGrey);
    text-align: center;
  }
  .weeklyTable__container.weekRatingTable.finalReport__table td {
    width: 10%;
  }
  .line-titel{
    border-bottom: 1px solid #D8D8D8;
    position: relative;
    margin-bottom: 30px;
    font-weight: 700;
    font-size: 14px;
    color: #414f63;
    text-transform: uppercase;
    
      span{
          position: absolute;
          left: 0px;
          background: #fff;
          padding: 1px;
          top: -8px;
            padding-right: 30px;
      }
}
.logo-titel{
    font-size: 16px;
    color: #47566c;
    letter-spacing: 0.5px;
    line-height: 18px;
    margin-top: 10px;
    margin-bottom: 0px;
}
.form-group .form-control{
  padding-right: 33px !important;
}
.form-field .form-control.text-area-box{
  min-height: 120px;
  border-radius: 2px;
  border-color: transparent !important;
  margin-top: 0px !important;
  &.is-invalid{
    border-color: #dc3545 !important;
  }
}
.btn_add{
  min-width: 120px;
}
.finalReport__medication{
  padding: 0px 8px;
  width: 100%;
}
.finalReport__subHead {
  font-weight: 700;
  display: inline-block;
  margin: 0;
  padding-left: 1.5%;
  font-size: 14px;
}
.heading.weekNote__heading {
  font-size: 12px !important;
  font-weight: 600 !important;
  position: relative !important;
}
.timestamp {
  color: #6b798e;
  font-weight: 400;
  position: absolute;
  right: 0px;
  top: -3px;
}
.down-arrow{
    //position: fixed;
    display: inline-block;
    width: 40px;
    align-items: center;
    height: 44px;
    border-radius: 4px;
    text-align: center;
    font-size: 25px;
    z-index: 1000;
    cursor: pointer;
    border: 1px solid #0078bf;
    background-color: #e5f1f8;
    &:hover{
      background: #0078bf;
      color: #fff;
    }
}
.left-0{
  left: 0px !important;
}
.additional-box{
  text-align: right;
  padding: 5px;
  border-top: 1px solid #ccc;
}
.additional-box .ant-btn{
  padding: 5px 10px;
  height: 30px !important;
}
.additional-box .ant-btn.btn-link{
  background-color: transparent;
  color: #0278be;
  border: none;
  box-shadow: none;
}
.top-main-text{
  small{
    font-size: 13px;
  }
}
.cursor-auto{
  label {
    cursor: auto !important;
  }
}