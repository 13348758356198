.week__content p.ccfRating__subHeading{
    position: absolute;
    left: 20%;
    top: 2px;
    font-style: italic;
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 500;
}
.rating__notes{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(1, 1fr);
    align-items: flex-start;
    justify-content: center;
    border: 1px solid var(--color-greyish2);
    height: calc(100vh - 437px);
    min-height: calc(100vh - 437px);
    overflow: auto;
    margin: 15px auto 0;
}
p.ccfPTHeading{
    position: absolute;
    left: 14%;
    top: 2px;
    font-style: italic;
    color: var(--color-primary);
    font-size: 16px;
    font-weight: 500;
}
.rating__notes p.guardian__note{
    font-size: 14px;
    font-weight: 600;
    color: var(--color-slaty);
    text-align: left;
    background-color: var(--color-gray4);
    padding: 15px;
}
.weekRatingNote__list{
    border-right: 1px solid var(--color-greyish2);
}
.weekRatingNote__list:last-child{
    border-right: 0;
}
.notes__content{
    padding: 10px 15px;
}
.timestamp {
    color: #6b798e;
    font-weight: 400;
    position: absolute;
    right: 0px;
    top: -3px;
}