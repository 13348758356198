.medical__data {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 17.5% 81%;
  grid-gap: 30px;
  grid-gap: 30px;
  gap: 30px;
  align-items: flex-start;
  justify-content: center;
  padding: 0 45px 0 0;
}
.search.search__list {
  padding: 0 15px;
  height: 38px;
  line-height: 38px;
}
.heading.medicalData__style {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 0;
  font-weight: 600;
}
.patient__formStatus {
  width: 100%;
  margin: 15px auto 10px;
  position: relative;
}
.patient__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3px;
}
.status__badge.patient__header_status {
  padding: 5px 8px;
  font-size: 11px;
}
.patient__summaryTab .tabsWrapper {
  border-right: 0;
}
.patient_summary__wrapper {
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 85% 15%;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.patient__summaryTab {
  width: 100%;
  margin: 20px 0 15px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.tabContainer.smd_tabs {
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  text-align: center;
  padding: 20px 5px;
}
.tabContainer.smd_tabs.activeTab {
  background-color: var(--color-primary);
  padding: 20px 10px;
}
.tabContainer.smd_tabs .step-text {
  padding: 0;
}
.tabContainer.smd_tabs.activeTab .step-text p span,
.tabContainer.smd_tabs.activeTab .step-text p br {
  display: none;
}
.tabContainer.smd_tabs .step-text img {
  background: none;
  width: 16px;
  min-width: 16px;
  padding: 0;
  border-radius: 0;
}

.tabContainer.smd_tabs .step-text p {
  color: var(--color-primary);
  font-weight: 600;
  padding-left: 8px;
  margin: 0;
  word-break: keep-all;
}
.tabContainer.smd_tabs .step-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabContainer.smd_tabs.activeTab .step-text p {
  color: var(--color-white);
  font-size: 14px;
}
.expand__accordian{
  width: 100%;
  margin: 0 auto;
  position: relative;
  text-align: center;
  cursor: pointer;
  background-color: var(--color-skyBluish);
  padding: 13px;
  border-radius: 5px;
}
.expand__accordian p{
  display: inline-block;
  padding-left: 10px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: var(--color-slaty);
  vertical-align: middle;
  margin: 0;
}
.scrollHeight{
  height: 74vh !important;
  min-height: 74vh;
  overflow: auto;
}
.user-icon.listIcon__style{
  background-color: #dedab7 !important;
}
.user-list .user-list-item .user-link:active,
.user-list .user-list-item .user-link:hover, 
.user-list .user-list-item .user-link:focus {
    background-color: var(--color-skyBluish);
}

.heading.SMH__heading{
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.SMH__viewHead{
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 10fr 1.5fr;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
}
.message-align {
  font-size: 18px;
  text-align: center;
  color: var(--color-slaty);
  font-weight: bold;
  line-height: 30px;
}
.weekly__listing__filters{
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-content: center;
  gap: 7px;
}
.weekly__listing__filters .tabsWrapper{
  border-right: 0;
}
/* responsive css */
@media screen and (max-width: 1500px) {
  .medical__data {
    gap: 15px;
    padding: 0 15px 0 0;
  }
  .SMH__viewHead{
    grid-template-columns: 10fr 2fr;
  }
}
@media screen and (max-width: 1430px) {
  .tabContainer.smd_tabs.activeTab .step-text p,
  .tabContainer.smd_tabs .step-text p {
    font-size: 12px;
    padding-left: 4px;
  }
  
  .search.search__list{
    padding: 0 10px;
    font-size: 13px;
  }
}
@media screen and (max-width: 1280px){
  .status__badge.patient__header_status{
    font-size: 10px;
  }
}

@media screen and (max-width: 1180px){
  .patient_summary__wrapper{
    grid-template-columns: 1fr;
  }
  .tabContainer.smd_tabs.activeTab{
    padding: 20px 5px;
  }
  .tabContainer.smd_tabs.activeTab .step-text p, .tabContainer.smd_tabs .step-text p{
    font-size: 11px;
  }
  .medical__data{
   grid-template-columns: 25% 74%;
   gap: 10px; 
  }
  .patient__header{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 4fr 3fr 3fr;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
  }
}
@media screen and (max-width:1100px){
  .SMH__viewHead{
    grid-template-columns: 9fr 3fr;
    gap: 5px;
  }
}
@media screen and (max-width:992px){
  .tabContainer.smd_tabs .step-text{
    display: block;
    text-align: center;
  }
}
@media screen and (max-width:860px){
  .medical__data{
    grid-template-columns: 23% 76%;
  }
  .patient__header{
    grid-template-columns: repeat(2, 1fr);
  }
  .heading.SMH__heading{
    font-size: 14px;
  }
  .link-text {
      text-decoration: underline;
  }
  .heading.medicalData__style{
    font-size: 14px;
  }
}