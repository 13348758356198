.severity__rating{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}
.severity__rating p{
    font-size: 12px;
    color: var(--color-slaty);
    margin: 0;
    font-weight: 400;
    letter-spacing: 0.3px;
}
.severity__rating p span{
    border: 1px solid var(--color-lightGray9);
    border-radius: 50%;
    margin-right: 5px;
    padding: 1px 6px 2px 6px;
    font-size: 10px;
    display: inline-flex;
}
.severity__rating p:nth-child(5) span{
    border: 1px solid var(--color-fadeLightOrange);
}

.severity__rating p:nth-child(7) span, .severity__rating p:nth-child(6) span{
    border: 1px solid var(--color-reddish);
}
.rating__wrapper{
    width: 100%;
    margin: 10px auto;
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 11% 89%;
    align-items: flex-start;
    justify-content: center;
    height: calc(100vh - 392px);
    min-height: calc(100vh - 392px);
    overflow:  auto;
}
.rating__tabs{
    width: 100%;
    /* height: calc(100vh - 310px);
    border-right: 1px solid var(--color-greyish2); */
}
/* .rating__tabs < .tabsWrapper .tabContainer.rating__tab.activeTab{
    border-right: transparent;
} */

.rating__tabs .tabsWrapper{
    border-right: 0;
}

.tabContainer.rating__tab.activeTab {
    border-top: 1px solid var(--color-greyish2);
    border-left: 5px solid var(--color-primary);
    border-right: 0;
    border-bottom: 0;
    background-color: var(--color-white);
    padding: 10px 30px;
}
.tabContainer.rating__tab.activeTab .step-text {
    display: inline-block;
    text-align: left;
}
.tabContainer.rating__tab {
    text-align: left;
    background-color: var(--color-gray4);
    padding: 13.9px 20px;
    border: 1px solid var(--color-greyish2);
}
.tabContainer.rating__tab .step-text{
    padding: 13.3px 0;
}
.tabContainer.rating__tab .step-text p.tab__familyName {
    font-size: 16px;
    font-weight: 600;
    color: var(--color-slaty);
    padding-top: 9px;
}
.tabContainer.rating__tab .step-text p {
    font-size: 13px;
    margin: 0;
    line-height: 16px;
}
.tabContainer.rating__tab.activeTab .step-text p.tab__familyName{
    font-weight: 600;
}
.tabContainer.rating__tab.activeTab .step-text p {
    font-weight: 400;
    padding-left: 0;
}
.tabContainer.rating__tab.activeTab .step-text p br {
    display: none;
}
.tabContainer.rating__tab .step-text img{
    background: none;
    width: 16px;
    min-width: 16px;
    padding: 0;
    border-radius: 0;
}
.remove-cursor {
    cursor: default !important;
}

.add-cursor {
    cursor: pointer !important;
}
@media screen and (max-width:1450px){
    .tabContainer.rating__tab .step-text p.tab__familyName{
        font-size: 11px;
    }
    .tabContainer.rating__tab .step-text p{
        font-size: 11px;
    }
    .tabContainer.rating__tab.activeTab, .tabContainer.rating__tab{
        padding: 6px 10px;
    }
}
@media screen and (max-width:1380px){
    .severity__rating{
        gap: 7px;
    }
}
@media screen and (max-width:1280px){
    .severity__rating p{
        font-size: 10px;
    }
}
@media screen and (max-width:1180px){
    .tabContainer.rating__tab .step-text p{
        font-size: 10px;
    }
    .tabContainer.rating__tab.activeTab, .tabContainer.rating__tab{
        padding:6px 5px;
    }
    .severity__rating{
        display: grid;
        align-items: center;
        justify-content: center;
        gap: 7px;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(4, 1fr);
    }
    .rating__wrapper{
        height: calc(100vh - 474px);
        min-height: calc(100vh - 474px);
    }
    .severity__rating p:nth-child(7){
        grid-column: 3 /span 2;
    }
}
@media screen and (max-width:992px){
    .rating__wrapper{
        grid-template-columns: 1fr;
        display: inline-block;
    }
    .rating__tabs{
        display: grid;
        grid-template-rows: 1fr;
        grid-template-columns: repeat(5, 1fr);
        align-items: center;
        justify-content: center;
    }
    .rating__wrapper {
        height: calc(100vh - 510px);
        min-height: calc(100vh - 510px);
    }
}