.page-titel{
    color: #322D46;
    font-size: 25px;
    font-weight: 500;
}
.list-section{
    overflow: hidden;
}
.mgmt__wrapper{
    width: 100%;
    margin: 0;
    position: relative;
    padding: 0 20px;
}
.mgmt__wrapper .patientMgmt__Tab{
    gap: 25px;
}
.patientMgmt__Tab{
    width: 100%;
  margin: 20px 0 15px;
  position: relative;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.patientMgmt__Tab .tabContainer.mgmt__tabs{
    box-shadow: 0 5px 16px 0 rgba(201, 198, 198, 0.3);
    border-radius: 5px;
    text-align: center;
    padding: 18px 0px 18px 30px;
}
.tabContainer.mgmt__tabs .step-text{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.tabContainer.mgmt__tabs .step-text img{
    background: none;
    width: 20px;
    min-width: 20px;
    height: 20px;
    padding: 0;
    border-radius: 0;
}
.tabContainer.mgmt__tabs .step-text p{
    padding-left: 15px;
    text-align: left;
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
}
.tabContainer.mgmt__tabs .step-text p span{
    font-size: 0.9rem;
    word-break: keep-all;
    line-height: 14px;
    white-space: pre;
}
.tabContainer.mgmt__tabs.activeTab{
    background-color: var(--color-primary);
}
.tabContainer.mgmt__tabs.activeTab .step-text{
    padding: 4px 0;
}
.tabContainer.mgmt__tabs.activeTab .step-text p, .tabContainer.mgmt__tabs.activeTab .step-text p span{
    color: var(--color-white);
}
.mgmt__filters{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 9fr 3fr;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 25px auto;
}
.mgmt__leftFilters{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 4fr 2fr 2fr 2fr 1fr;
    gap: 15px;
    align-items: center;
    justify-content: center;
}
.search.search__filter{
    height: 50px;
    border-radius: 5px;
    padding: 0px 10px 0 60px;
    font-size: 12px;
    text-align: left;
    border: 1px solid var(--color-lightSkyBlue);
    margin-top: 11px;
    letter-spacing: 0.5px;
}
.search.search__filter:placeholder-shown{
    font-size: 11px;
    text-overflow: ellipsis;
    overflow: hidden;

}
.mgmt__leftFilters p{
    margin: 0;
    text-align: left;
    color: var(--color-primary);
    font-weight: 600;
    letter-spacing: 0.5px;
    cursor: pointer;
    margin-top: 10px;
}
.mgmt__leftFilters .searchBox img{
    top: 26px;
}
.range__margin{
    margin-top: 11px;
}
.patient__summaryTab .ant-tooltip{
    top: 80px !important;
}

@media screen and (max-width:1720px){
    .tabContainer.mgmt__tabs .step-text p{
        font-size: 18px;
        padding-left: 10px;
    }
    .tabContainer.mgmt__tabs .step-text p span{
        font-size: 12px;
    }
    .patientMgmt__Tab .tabContainer.mgmt__tabs{
        padding: 18px 10px;
    }
    .mgmt__wrapper .patientMgmt__Tab{
        gap: 10px;
    }
    .tabContainer.mgmt__tabs .step-text img{
        width: 22px;
        min-width: 22px;
    }
    .search.search__filter{
        padding: 0 2px 0 25px;
        font-size: 11px;
    }
    .searchBox img{
        left: 6px;
        width: 15px;
    }
}
@media screen and (max-width:1500px){
    .mgmt__leftFilters{
        gap: 5px;
    }
    .search.search__filter::placeholder{
        font-size: 10px;
    }
    .tabContainer.mgmt__tabs .step-text p{
        padding-left: 5px;
    }
    .mgmt__wrapper{
        padding: 0 10px;
    }
    .tabContainer.mgmt__tabs .step-text p span {
        font-size: 11px;
    }
    .tabContainer.mgmt__tabs .step-text img {
        width: 20px;
        min-width: 20px;
    }
    .patientMgmt__Tab .tabContainer.mgmt__tabs {
        padding: 18px 5px;
    }
    .mgmt__wrapper .patientMgmt__Tab {
        gap: 6px;
    }
}
@media screen and (max-width:1280px){
  
    .tabContainer.mgmt__tabs .step-text{
        display: grid;
        align-items: center;
        justify-content: center;
        grid-template-rows: 1fr;
        grid-template-columns: 1fr;
        gap: 5px;
    }
    .tabContainer.mgmt__tabs .step-text img, .tabContainer.mgmt__tabs .step-text p{
        margin: 0 auto;
        text-align: center;
    }
    .tabContainer.mgmt__tabs.activeTab .step-text{
        padding:0;
    }
    .tabContainer.mgmt__tabs .step-text p{
        padding-left: 0;
    }
}
@media screen and (max-width:992px){
    .mgmt__leftFilters p{
        font-size: 12px;
        text-align: left;
    }
    .mgmt__leftFilters{
        grid-template-columns: 3fr 2fr;
    }
    .tabContainer.mgmt__tabs .step-text p span{
        font-size: 10px;
    }
   
    .mgmt__wrapper .patientMgmt__Tab{
        gap: 5px;
    }
}
@media screen and (max-width:860px){
    .tabContainer.mgmt__tabs .step-text p span{
        font-size: 9px;
        white-space: nowrap;
    }
   
}



