.heading.weekNote__heading {
  font-size: 14px;
  font-weight: 600;
  position: relative;
}
.heading.weekNote__heading .weekNote__subHeading {
  font-style: italic;
}
.weekNote__list {
  width: 100%;
  margin: 10px auto;
  position: relative;
  overflow: auto;
  height: calc(100vh - 600px);
  min-height: calc(100vh - 600px);
}
.drawer__btn {
  margin: 40px auto;
  display: inline-block;
}
.btn__margin {
  margin-right: 20px;
}
.time-stamp {
  color: #6b798e;
  font-weight: 400;
  position: absolute;
  right: 0px;
  top: -3px;
}
/* @media screen and (max-width:1380px){
    .weekNote__list{
        height: 19vh;
        min-height: 19vh;
    }
} */
