.weekly__severity_wrapper{
    width: var(--container-width);
    margin: 0 auto;
    position: relative;
}
.weekly__severity_container{
   width: 100%;
    margin: 0 auto;
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-content: space-between;
}
.weekly__severity_container .tabsWrapper{
    border-right: none;
}

.heading__style{
    margin-left: 18px;
    width: 40vw;
}
/* .teacherHeading__style{
    margin-left: -65px;
} */
.headingAdmin__style{
    margin-left: -15px;
}
.childstatus__wrapper{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.medical__view{
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-decoration: underline;
    color: var(--color-primary);
}
.medical__view a{
    color: var(--color-primary);
}
.tabContainer.weeklySev__tab{
    text-align: center;
    background-color: var(--color-gray4);
    padding: 23px 40px;
    border: 1px solid var(--color-greyish2);
} 
.tabContainer.weeklySev__tab .step-text p.tab__familyName{
    font-size: 16px;
    font-weight: 600;
    color: var(--color-slaty);
}
.tabContainer.weeklySev__tab .step-text p{
    font-size: 13px;
    margin: 0;
    line-height: 16px;
}
.tabContainer.weeklySev__tab.activeTab{
    border-top: 5px solid var(--color-primary);
    border-left: 1px solid var(--color-primary);
    border-right: 1px solid var(--color-primary);
    border-bottom: 0;
    border-radius: 5px 5px 0 0;
    background-color: var(--color-white);
    padding: 6px 40px;
}
.tabContainer.weeklySev__tab.activeTab .step-text{
    display: inline-block;
    text-align: center;
}
.tabContainer.weeklySev__tab.activeTab .step-text p{
    padding-left: 0;
}
.tabContainer.weeklySev__tab.activeTab .step-text p br{
    display: none;
}
.tabContainer.weeklySev__tab.activeTab .step-text p.tab__familyName{
    font-weight: 600;
}
.tabContainer.weeklySev__tab.activeTab .step-text p{
    font-weight: 400;
}
.weekly__tableStr{
    width: 100%;
    margin: 0 auto;
    position: relative;
    border: 1px solid var(--color-greyish2);
    border-radius: 0 0 2px 2px;
    border-top: 0;
    padding: 20px;
}
.weekly__statusWrap{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 3fr 2fr;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;
}
.week__noteBtn{
    width: 100%;
    float: right;
    text-align: right;
}
.week__note.ant-btn-primary{
    border-radius: 5px;
  height: 45px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: var(--color-slaty);
  background-color: var(--color-lightBlue);
  border-color: var(--color-lightBlue);
  padding: 6.4px 7px;
}
.week__note.ant-btn-primary:hover{
    background-color: var(--color-lightBlue);
    border: 1px solid var(--color-primary);
}
.week__note_img{
    padding-right: 10px;
}
.weeklyChildTable__container{
    width: 100%;
    margin: 0 auto;
    position: relative;
    overflow: auto;
    /* height: 54vh; */
    height: calc(100vh - 425px);
    min-height: calc(100vh - 425px);
    overflow-x: hidden;
}
.weeklyChildTable__container .ant-popover-arrow{
    display: none;
}

.weeklyChildTable__container .ant-popover-content{
    width: 235px;
    border: 1px solid var(--color-grey1);
    box-shadow: 1px 1px 15px 0 rgba(0,0,0,0.1);
    border-radius: 5px;
    background-color: var(--color-white);
    position: relative;
}
.weeklyChildTable__container table {
    position: relative;
    border-collapse: collapse;
    width: 100%;
}
  
.weeklyChildTable__container th, .weeklyChildTable__container td {
    width: 16%;
}

.weeklyChildTable__container tr.side__effects th{
    height: 55px;
    padding: 0 20px;
    font-size: 16px;
    box-shadow: 0 0 0 0;
    border-right: 1px solid var(--color-greyish);
    padding-left: 50px;
}
.weeklyChildTable__container tr.side__effects th {
    background: var(--color-lightGrey);
    color: var(--color-slaty);
    z-index: 1;
}
.weeklyChildTable__container tr.side__effects th:last-child{
    border-right: 0;
}

.weeklyChildTable__container th {
    background: white;
    position: sticky;
    top: 0;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    
}
.weeklyChildTable__container table tbody tr:first-child{
    border-top: 1px solid var(--color-greyish1);
}
.weeklyChildTable__container td{
    border-right: 1px solid var(--color-greyish);
    border-bottom: 1px solid var(--color-greyish);
    padding: 0;
    position: relative;
}
.weeklyChildTable__container table thead th:first-child
.weeklyChildTable__container table tbody tr td:first-child{
    position: sticky;
}
/* .weeklyChildTable__container table thead th:nth-child(7),
.weeklyChildTable__container table thead th:nth-child(8),
.weeklyChildTable__container table thead th:nth-child(9),
.weeklyChildTable__container table tbody tr td:nth-child(7),
.weeklyChildTable__container table tbody tr td:nth-child(8),
.weeklyChildTable__container table tbody tr td:nth-child(9){
    display: none;
} */
.remove-cursor {
    cursor: default !important;
}

.add-cursor {
    cursor: pointer !important;
}
/* .weeklyChildTable__container table thead:hover ~ .week__btn.sliderLeft, 
.weeklyChildTable__container table thead:hover ~ .week__btn.sliderBtn{
   display: inline-grid;
    z-index: 200;
    transition: opacity 8s ease-in-out;
    cursor: pointer;
}
.weeklyChildTable__container .week__btn.sliderLeft{
    left: 30%;
}
.week__btn.sliderLeft, 
.week__btn.sliderBtn{
  display: none;
} */

@media screen and (max-width: 1430px){
    .heading__style {
        margin-left: 12px;
    }
}
@media screen and (max-width:1180px){
    .weeklyChildTable__container{
        overflow-x: scroll;
        scroll-behavior: smooth;
    }
   
}

@media screen and (max-width:1024px){
    .weekly__statusWrap{
        grid-template-columns: 5fr 2fr;
        gap: 15px;
    }
    .tabContainer.weeklySev__tab.activeTab{
        padding: 6px 10px;
    }
    .tabContainer.weeklySev__tab .step-text p{
        font-size: 12px;
    }
    .tabContainer.weeklySev__tab .step-text p.tab__familyName{
        font-size: 12px;
    }
    .tabContainer.weeklySev__tab{
        padding: 23px 15px;
    }
}
@media screen and (max-width:1000px){
    .weekly__severity_wrapper{
        width: 98%;
    }
    .childstatus__wrapper{
        grid-template-columns: 5fr 3fr 3fr 3fr;
    }
    .tabContainer.weeklySev__tab.activeTab{
        padding: 5px;
    }
    .tabContainer.weeklySev__tab.activeTab .step-text{
        padding: 15px 0;
    }
    .weeklyChildTable__container tr.side__effects th{
        padding-left: 35px;
    }
    .headingAdmin__style{
        margin-left: 0;
    }
    .week__note.ant-btn-primary{
        font-size: 12px;
    }
    /* .weeklyChildTable__container table thead th:nth-child(7),
.weeklyChildTable__container table thead th:nth-child(8),
.weeklyChildTable__container table thead th:nth-child(9),
.weeklyChildTable__container table tbody tr td:nth-child(7),
.weeklyChildTable__container table tbody tr td:nth-child(8),
.weeklyChildTable__container table tbody tr td:nth-child(9){
    display: table-cell;
} */
}