.header__wrapper, .header__admin {
  width: 100%;
  margin: 0 auto;
  background-color: var(--color-white);
  height: 85px;
  top: 0;
  z-index: 99;
  box-shadow: 2px 5px 16px 0 rgba(73,88,96,0.1);
}
.header__wrapper{
  position: relative;
}
.header__admin{
  position: relative;
}