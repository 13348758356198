.pagination .page-item{
    margin-right: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 14px;
}
.pagination .page-item .page-link{
    color: var(--color-black);
    border-radius: 0.25rem;
}
.pagination .page-item.disabled .page-link{
    background-color: var(--color-lightBlue);
    border: 1px solid var(--color-lightBlue);
    color: var(--color-primary);
}
.pagination .page-item.active .page-link {
    z-index: 3;
    color: var(--color-white);
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    border-radius: 0.25rem;
}
@media screen and (max-width:992px){
    .pagination .page-item{
        font-size: 12px;
    }
    .page-link{
        padding:0.5rem;
    }
}