*{
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings:"lnum" 1; 
  -moz-font-feature-settings:"lnum=1"; 
  -ms-font-feature-settings:"lnum" 1; 
  -o-font-feature-settings:"lnum" 1; 
  -webkit-font-feature-settings:"lnum" 1; 
  font-feature-settings:"lnum" 1;
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif !important;
  color: #414f63;
  font-feature-settings: 'lnum' 1;
  font-variant-numeric: lining-nums;
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings:"lnum" 1; 
  -moz-font-feature-settings:"lnum=1"; 
  -ms-font-feature-settings:"lnum" 1; 
  -o-font-feature-settings:"lnum" 1; 
  -webkit-font-feature-settings:"lnum" 1; 
  font-feature-settings:"lnum" 1;
}

.header-top {
  background: #fff;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0px;
  /* border-bottom: 1px solid #ccc; */
  left: 0px;
  z-index: 999;
  padding: 10px 20px;
}

.main-content {
  /* padding: 60px 0px 0px 95px; */
  width: 100%;
  margin: 0 auto;
  position: relative;
  min-height: 89vh;
}
.ant-layout-sider {
  z-index: 999;
  background: var(--color-primary) !important;
}
.ant-layout {
  background-color: var(--color-white) !important;
}

/* custom scrollbar */
.SMH__ScrollBar,
.scrollBar,
.scrollBar__physician,
.finalReport__container,
.weeklyScrollBar {
  overflow: auto;
}
.scrollBar__user {
  height: calc(100vh - 234px);
  min-height: calc(100vh - 234px);
}
.SMH__ScrollBar {
  /* height: 61vh; */
  height: calc(100vh - 359px);
  min-height: calc(100vh - 359px);
}
.scrollBar {
  /* height: 81vh; */
  /* min-height: 81vh; */
  height: calc(100vh - 225px);
  min-height: calc(100vh - 225px);
}
.scrollBar__physician {
  /* height: 75vh; */
  height: calc(100vh - 233px);
  min-height: calc(100vh - 233px);
}
.finalReport__container {
  height: calc(100vh - 304px);
  min-height: calc(100vh - 304px);
  padding-left: 5px;
}
.weeklyScrollBar {
  height: calc(100vh - 168px);
  min-height: calc(100vh - 168px);
}

.scrollBar::-webkit-scrollbar,
.SMH__ScrollBar::-webkit-scrollbar,
.weeklyTable__container::-webkit-scrollbar,
.weeklyChildTable__container::-webkit-scrollbar,
.weekNote__list::-webkit-scrollbar,
.scrollBar__physician::-webkit-scrollbar,
.rating__notes::-webkit-scrollbar,
.scrollBar__user::-webkit-scrollbar,
.finalReport__container::-webkit-scrollbar,
.rating__wrapper::-webkit-scrollbar,
.weeklyScrollBar::-webkit-scrollbar,
.mgmt__table::-webkit-scrollbar,
.medSet__form::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

.scrollBar::-webkit-scrollbar-track,
.SMH__ScrollBar::-webkit-scrollbar-track,
.weeklyTable__container::-webkit-scrollbar-track,
.weeklyChildTable__container::-webkit-scrollbar-track,
.weekNote__list::-webkit-scrollbar-track,
.scrollBar__physician::-webkit-scrollbar-track,
.rating__notes::-webkit-scrollbar-track,
.scrollBar__user::-webkit-scrollbar-track,
.finalReport__container::-webkit-scrollbar-track,
.rating__wrapper::-webkit-scrollbar-track,
.weeklyScrollBar::-webkit-scrollbar-track,
.mgmt__table::-webkit-scrollbar-track,
.medSet__form::-webkit-scrollbar-track {
  background-color: #fff;
}

.scrollBar::-webkit-scrollbar-track:hover,
.SMH__ScrollBar::-webkit-scrollbar-track:hover,
.weeklyTable__container::-webkit-scrollbar-track:hover,
.weeklyChildTable__container::-webkit-scrollbar-track:hover,
.weekNote__list::-webkit-scrollbar-track:hover,
.scrollBar__physician::-webkit-scrollbar-track:hover,
.rating__notes::-webkit-scrollbar-track:hover,
.scrollBar__user::-webkit-scrollbar-track:hover,
.finalReport__container::-webkit-scrollbar-track:hover,
.rating__wrapper::-webkit-scrollbar-track:hover,
.weeklyScrollBar::-webkit-scrollbar-thumb:hover,
.mgmt__table::-webkit-scrollbar-thumb:hover,
.medSet__form::-webkit-scrollbar-thumb:hover {
  background-color: #f4f4f4;
}

.scrollBar::-webkit-scrollbar-thumb,
.SMH__ScrollBar::-webkit-scrollbar-thumb,
.weeklyTable__container::-webkit-scrollbar-thumb,
.weeklyChildTable__container::-webkit-scrollbar-thumb,
.weekNote__list::-webkit-scrollbar-thumb,
.scrollBar__physician::-webkit-scrollbar-thumb,
.rating__notes::-webkit-scrollbar-thumb,
.scrollBar__user::-webkit-scrollbar-thumb,
.finalReport__container::-webkit-scrollbar-thumb,
.rating__wrapper::-webkit-scrollbar-thumb,
.weeklyScrollBar::-webkit-scrollbar-thumb,
.mgmt__table::-webkit-scrollbar-thumb,
.medSet__form::-webkit-scrollbar-thumb {
  background-color: #ababab;
  border-radius: 16px;
  border: 5px solid #fff;
}

.scrollBar::-webkit-scrollbar-thumb:hover,
.SMH__ScrollBar::-webkit-scrollbar-thumb:hover,
.weeklyTable__container::-webkit-scrollbar-thumb:hover,
.weeklyChildTable__container::-webkit-scrollbar-thumb:hover,
.weekNote__list::-webkit-scrollbar-thumb:hover,
.scrollBar__physician::-webkit-scrollbar-thumb:hover,
.rating__notes::-webkit-scrollbar-thumb:hover,
.scrollBar__user::-webkit-scrollbar-thumb:hover,
.finalReport__container::-webkit-scrollbar-thumb:hover,
.rating__wrapper::-webkit-scrollbar-thumb:hover,
.weeklyScrollBar::-webkit-scrollbar-thumb:hover,
.mgmt__table::-webkit-scrollbar-thumb:hover,
.medSet__form::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

.scrollbar::-webkit-scrollbar-button,
.SMH__ScrollBar::-webkit-scrollbar-button,
.weeklyTable__container::-webkit-scrollbar-button,
.weeklyChildTable__container::-webkit-scrollbar-button,
.weekNote__list::-webkit-scrollbar-button,
.scrollBar__physician::-webkit-scrollbar-button,
.rating__notes::-webkit-scrollbar-button,
.scrollBar__user::-webkit-scrollbar-button,
.finalReport__container::-webkit-scrollbar-button,
.rating__wrapper::-webkit-scrollbar-button,
.weeklyScrollBar::-webkit-scrollbar-button,
.mgmt__table::-webkit-scrollbar-button,
.medSet__form::-webkit-scrollbar-button {
  display: none;
}

/* custom scrollbar */

.react-calendar {
  position: absolute;
  z-index: 99;
}

.Toastify__toast--success{
  background: #00843d !important;
}
.user-details{
  position: relative;
  width: 100%;
  padding-left: 40px;
}
.user-details .name_wrap{
  position: absolute !important;
  left: 0px !important;
  top: 0px !important;
}
.additional-box{
  text-align: right;
  padding: 5px;
}
.additional-box .ant-btn{
  padding: 5px 10px;
  height: 30px !important;
}
.additional-box .ant-btn.btn-link{
  background-color: transparent;
  color: #0278be;
  border: none;
  box-shadow: none;
}
.width-unset{
  width: unset !important;
}

@media (max-width: 1200px) {
  /* .medication__form{
    height: 100% !important;
  }
  .scrollBar{
    height:  100% !important;
  } */

  .tabContainer.listFilterTab .step-text img{
    min-width: 22px !important;
    width: 22px !important;
    padding: 4px !important;
  }
  .add__icon.anticon-plus{
    padding: 2px !important;
  }

  .card-view{
    padding: 25px 6px 25px 5px !important;
  }
  .breck-box-details .viewMed__head{
    width: 100% !important;
    display: block;
  }
  .breck-box-details .ccf__heading, .breck-box-details .viewMed__rightContent{
    width: 100% !important;
    display: block;
  }
  .breck-box-details .viewMed__rightContent .ccf__heading{
    max-width: 33.33%;
    width: 100%;
    display: inline-block;
    text-align: left  !important;
  }
  .no-wrap{
    white-space: nowrap;
  }
  /* .child-info .row{
    margin: 0px;
  } */
  .child-info .col-md-3, .child-info .col-md-4{
    padding-right: 15px !important;
  }
  .child-info .col-md-6{
    padding-right: 10px;
  }
  .child-info .col-md-11{
    width: 100%;
  }
  .child-info .addPatient__col4{
    padding-right: 0px !important;
  }
  .addPatient__col4{
    padding-right: 0px !important;
  }
}

@media (max-width: 650px) {
  header {
    display: flex;
    flex-direction: column;
  }
}
.medical-group .form-label {
  padding: 0px 2px;
}
.form-field .form-label{
  padding: 0px 2px !important;
}
.childTab__status .viewMed__weekContent{
  background-color: #D5E6EF !important;
}