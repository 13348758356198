.accordian .panel_header {
    width: 100%;
    margin: 0 auto 10px;
    position: relative;
    background-color: var(--color-lightGrayShade);
}
.ant-collapse > .ant-collapse-item.panel_header > .ant-collapse-header {
    color: var(--color-slaty);
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    letter-spacing: 0.5px;
    /* font-variant-caps: all-small-caps; */
    position: relative;
    padding: 20px 30px;
    padding-left: 30px;
}
.ant-collapse-content{
    border-top: 0;
}
.ant-collapse{
    border: 0;
}
.ant-collapse > .ant-collapse-item{
    border-bottom: 0;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    transform: rotate(180deg);
    font-size: 16px;
    top: 40%;
    color: var(--color-primary);
    right: 25px !important;
    text-align: left;
}
.ant-collapse-content > .ant-collapse-content-box{
    border: 1px solid var(--color-shadeGray);
    background-color: var(--color-greyShade);
}
[data-theme="compact"]
    .site-collapse-custom-collapse
    .site-collapse-custom-panel,
  .site-collapse-custom-collapse .site-collapse-custom-panel {
    background: #f7f7f7;
    border-radius: 2px;
    margin-bottom: 24px;
    border: 0px;
    overflow: hidden;
}
[data-theme="dark"] .site-collapse-custom-collapse .site-collapse-custom-panel {
    background: rgba(255, 255, 255, 0.04);
    border: 0px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra{
    float: left;
    padding-right: 30px;
}
  