.guardian_detail {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.guardian_detail p {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-darkSlaty);
  margin: 0;
  letter-spacing: 0.5px;
  margin-bottom: 2px;
}
.guardian_detail h5 {
  font-size: 14px;
  color: var(--color-slaty);
  font-weight: 600;
  margin: 0;
  letter-spacing: 0.5px;
  margin-bottom: 2px;
  word-break: break-word;
}
@media screen and (max-width:1067px){
  .guardian_detail h5{
    font-size: 12px;
  }
}
@media screen and (max-width:992px){
  .guardian_detail p{
    font-size: 10px;
  }
}