body {
  /* font-family: "ProximaNova-Regular"; */
  --color-slaty: #414f63;
  --color-lightSkyBlue: #b5c3cf;
  --color-darkSlaty: #8692a1;
  --color-primary: #0078bf;
  --color-offWhite: #e0e0e0;
  --color-lightYellow: #dedab7;
  --color-white: #ffffff;
  --color-black: #333333;
  --color-lightBlue: #e0f0fa;
  --color-parrot: #5bdb5a;
  --color-yellow: #ffca61;
  --color-lightWhite: #dddddd;
  --color-navyBlue: #322d46;
  --color-lightBlack: #3d3d3d;
  --color-extralightBlue: #9ca6b3;
  --color-lightGray: #f2f2f2;
  --color-lightnavyBlue: #b4cad7;
  --color-lightGrey: #f3f5f7;
  --color-slimBlack: #7c899d;
  --color-slimRed: #f88b85;
  --color-shadeGray: #F9FAFB;
  --color-lightShimBlue: #E4F3FB;
  --color-blueShade: #BDD5E4;
  --color-lightGreyish: #E5E2E2;
  --color-fadeGreen: #CCE4CB;
  --color-fadeOrange: #EAEAEA;
  --color-grayShade: #F3F6FA;
  --color-lightGrayShade: #E9EDF1;
  --color-greyShade: #FBFBFB;
  --color-greyish: #DBDFEC;
  --color-greyish2: #DBDEEA;
  --color-greyish1: #F0ECF8;
  --color-fadeParrot: #EDFFEC;
  --color-fadeLightOrange: #FDD1B0;
  --color-reddish: #EE93AE;
  --color-darkRed: #F85252;
  --color-grey: #EAECEF;
  --color-grey1: #E8E8E8;
  --color-lightShimmerBlue: #E5F1F8;
  --color-eucalyptus: #1FDC35;
  --color-lightPink: #FCDCDA;
  --color-gray3: #8C8A95;
  --color-gray4: #F4F4F4;
  --color-skyBluish: #E5F0FB;
  --color-lightCrayon:  #fff3f3;
  --color-lightSlaty: #485465;
  --color-shadeSlaty: #F2F7FE;
  --color-dimGrey: #D8D8D8;
  --color-shadeNavyBlue: #E2EDFD;
  --color-dimslaty: #384756;
  --color-darkishBlue: #D5E6EF;
  --color-gray5: #E1E6E8;
  --color-gray6: #EEF0F3;
  --color-gray7: #C9DDEA;
  --color-lightGray8: #ABB4BF;
  --color-lightGray9: #CFCFCF;
  --color-lightGray10: #C4CDD7;
  --color-primaryDark: #005A8F;
  --color-shadeOrange: #F08122;
  --color-shadeGreen: #00843D;
  --color-shadeBrown: #4B4B45;

  --color-darkSeaGreen: #8fbc92;
  --color-shadyLady: #979797;
  --color-cinnabar: #e74133;
  --color-selectiveYellow: #f9bb00;
  --color-havelockBlue: #4c76be;
  --color-cornflowerBlue: #3e82f1;
  --color-spindle: #b1c3d3;
  --color-mariner: #4267b2;
  --color-gray: #f1f1f1;
  --color-red: #e02020;
  --color-crayon: #c7b3b3;
  --color-darkGray: #676767;
  --color-extradarkGray: #6d6d6d;
  --container-width: 95%;
  height: 89vh;
}
