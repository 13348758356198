.heading {
  font-size: 25px;
  color: var(--color-slaty);
  font-weight: bold;
  line-height: 30px;
}
.ccf__heading p {
  font-size: 14px;
  line-height: 16px;
  color: var(--color-slaty);
  text-align: left;
  letter-spacing: 0.3px;
}
@media screen and (max-width:1320px){
  .ccf__heading p{
    font-size: 13px;
  }
}
@media screen and (max-width:1250px){
  .ccf__heading p{
    font-size: 12px;
  }
}