.heading.weekNote__heading{
    font-size: 14px;
    font-weight: 600;
    color: var(--color-slaty);
}
.weekNote__subHeading{
    font-style: italic;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-slaty);
    text-align: left;
    letter-spacing: 0.3px;
    display: inline-block;
    word-break: keep-all;
    white-space: pre-wrap;
}
.weekNote__list{
    width: 100%;
    margin: 10px auto;
    position: relative;
    overflow: auto;
    height: calc(100vh - 600px);
    min-height: calc(100vh - 600px);
}
.drawer__btn{
    margin: 40px auto;
    display: inline-block;
}
.btn__margin{
    margin-right: 20px;
}
.time-stamp {
    color: #6b798e;
    margin-left: 360px;
    font-weight: 400;
}
/* @media screen and (max-width:1380px){
    .weekNote__list{
        height: 19vh;
        min-height: 19vh;
    }
} */
