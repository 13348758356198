.header {
  width: 97%;
  margin: 0 auto;
  display: flex;

  align-items: center;
  justify-content: space-between;
  height: 85px;
  /* position: fixed;
  top: 0px;
  left: 0px; */
  z-index: 99;
  background-color: var(--color-white);
}
.search__wrapper {
  width: 100%;
  position: relative;
}
.socialHeader__right {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 9fr 180px;
  align-items: center;
  justify-content: center;
  gap: 25px;
  text-align: right;
}
.ccf_username {
  text-overflow: ellipsis;
  display: inline-block;
  color: var(--color-slaty);
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  padding-left: 5px;
  margin: 0;
  vertical-align: middle;
  text-align: left;
  width: 120px;
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
}
.ccf_notificatn {
  position: relative;
  text-align: right;
}
.ccf_username span {
  font-size: 12px;
  color: var(--color-darkSlaty);
  line-height: 14px;
}
.notification {
  cursor: pointer;
  position: relative;
}
.badge.ant-badge {
  position: absolute;
  right: 0;
}
.ccf_menu ul {
  list-style: none;
  padding: 0;
  letter-spacing: 0.5px;
}
.ccf_menu ul li {
  color: var(--color-navyBlue);
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  margin: 10px auto;
}
.ccf_menu ul li:hover {
  color: var(--color-primary);
  /* text-decoration: underline; */
}
.ccf_profile {
  width: 50px;
  border-radius: 50%;
  padding: 12px 10px;
  border: 1px solid var(--color-darkSlaty);
}
.anticon.menu_icons {
  color: var(--color-primary);
  vertical-align: middle;
  font-size: 15px;
}
.anticon.anticon-upload.menu_icons {
  transform: rotate(90deg);
}
.ccf_menu ul li a,
.ccf_menu ul li p {
  padding-left: 10px;
}
.ccf_menu ul li p {
  display: inline-block;
  margin: 0;
}

.heading {
  font-size: 25px;
  color: var(--color-slaty);
  font-weight: bold;
  line-height: 30px;
  /* padding-left: 6px; */
}
.profile-icon {
  width: 50px;
  height: 50px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 10px;
  background-color: #dedab7;
  font-size: 16px;
}
.pagetitle__heading {
  font-size: 25px;
  color: var(--color-slaty);
  font-weight: bold;
  line-height: 30px;
  padding-left: 11px;
  display: inline-block;
  margin-bottom: 1px;
}
.breadcrum__heading {
  padding-left: 11px;
  color: var(--color-darkSlaty);
  letter-spacing: 0.5px;
  font-size: 14px;
  font-weight: 400;
}
.breadcrum__heading a {
  color: var(--color-darkSlaty);
}
.breadcrum__heading a:hover {
  color: var(--color-primary);
}
.ccf__logo__content {
  display: inline-block;
  align-items: center;
  justify-content: center;
  border-right: 1px solid var(--color-greyish2);
  width: 35%;
  margin: 0 auto;
}
.ccf__logo__content h3 {
  font-size: 45px;
  font-weight: 700;
  color: var(--color-primary);
  margin: 0;
  padding-right: 7px;
  display: inline-block;
}
.ccf__logo__content p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: var(--color-primary);
  margin: 0;
  display: inline-block;
}
.pagetitle__heading.pageTitle__set {
  margin-left: -45px;
}
.addPatient__img {
  width: 22px;
  margin-right: 12px;
}
.search__wrapper .btn_add.ant-btn-primary:hover,
.search__wrapper .btn_add.ant-btn-primary:hover,
.search__wrapper .btn_add.ant-btn-primary:active {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.editProfile__modal {
  width: 670px !important;
}
.editProfile__modal .ant-modal-content {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
}
.editProfile__modal .ant-modal-close {
  right: 11px;
}
.editProfile__modal .ant-modal-close:focus {
  outline: 0;
}
.editProfile__modal .ant-modal-body {
  padding: 30px;
}
.editProfile__modal .ant-modal-footer {
  padding: 0 30px 30px;
  border-top: 0;
  text-align: initial;
}
.edit__modal h3 {
  font-size: 18px;
  color: var(--color-slaty);
  font-weight: 600;
  letter-spacing: 0.5px;
}
.edit__form,
.edit__form__row {
  display: flex;
  grid-template-rows: 1fr;
  gap: 10px;
  align-items: center;
  justify-content: center;
  margin: 25px auto;
}
.edit__form {
  grid-template-columns: repeat(2, 1fr);
}
.edit__form__row {
  grid-template-columns: 1fr;
}
.fa-angle-down.relationPopup_arrow {
  position: absolute;
  right: 10px;
  /* top: 0; */
  bottom: 14px;
  color: #b5c3cf;
  font-size: 24px;
}
.editProfile__modal .ant-modal-footer .ant-btn,
.editProfile__modal .ant-modal-footer .ant-btn.ant-btn-primary {
  border: 1px solid var(--color-primary);
  border-radius: 5px;
  padding: 4px 30px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
}
.editProfile__modal .ant-modal-footer .ant-btn {
  color: var(--color-primary);
}
.editProfile__modal .ant-modal-footer .ant-btn.ant-btn-primary {
  border: 1px solid var(--color-lightBlue);
  color: var(--color-primary);
  background-color: var(--color-lightBlue);
  float: right;
}
.editProfile__modal .ant-modal-footer .ant-btn.ant-btn-primary:hover {
  border: 1px solid var(--color-primary);
}
/* @media screen and (max-width: 1580px) {
  .socialHeader__right {
      grid-template-columns: 6fr 1fr 3.5fr;
  }
} */
@media screen and (max-width: 1480px) {
  .ccf__logo__content {
    width: 45%;
  }
  .pagetitle__heading {
    font-size: 18px;
  }
}
@media screen and (max-width: 1210px) {
  .ccf__logo__content p {
    font-size: 12px;
  }

  .ccf__logo__content h3 {
    font-size: 40px;
  }
}
/* @media screen and (max-width: 1180px) {
  .socialHeader__right {
      grid-template-columns: 4fr 1fr 3.5fr;
  }
} */
/* @media screen and (max-width:1105px) {
  .ccf__logo__content {
      width: 40%;
  }
} */
@media screen and (max-width: 1067px) {
  .socialHeader__right {
    gap: 0px;
  }
 
  .ccf_username {
    padding-left: 8px;
  }
}
@media screen and (max-width: 992px) {
  /* .socialHeader__right {
      grid-template-columns: 5fr 1.5fr 4fr;
      gap: 5px;
  } */

  .profile-icon {
    width: 38px;
    height: 38px;
    font-size: 12px;
  }
  .ccf_username {
    font-size: 12px;
  }
  .ccf_username span {
    font-size: 11px;
  }
  
  .ccf__logo__content {
    width: auto;
    padding-right: 10px;
  }
  .ccf__logo__content h3 {
    font-size: 35px;
  }
  .ccf__logo__content p {
    font-size: 10px;
    line-height: 12px;
  }
  .pagetitle__heading {
    font-size: 15px;
    padding-left: 5px;
  }
  .breadcrum__heading {
    padding-left: 5px;
  }
}
@media screen and (max-width: 850px) {
  /* .socialHeader__right {
      grid-template-columns: 4fr 1.5fr 4.5fr;
  } */
  .breadcrum__heading {
    font-size: 13px;
  }
}
/* @media screen and (max-width: 722px) {
  .socialHeader__right {
      grid-template-columns: 4fr 1fr 4.5fr;
  }
} */

.user-info {
  min-width: 190px;
}
.ant-menu-inline-collapsed .sidebar-brand {
  width: 100%;
  overflow: hidden;
  padding-left: 6px;
}
.ant-menu {
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu-item {
  transition: border-color 0.5s, background 0.5s,
    padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
