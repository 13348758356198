.welcome__screen {
    width: 95%;
    margin: 85px auto 10px;
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);
}

.welcome__content{
    width: 65%;
    margin: 0 auto;
    position: relative;
}
.welcome__formImg{
    text-align: center;
}
.welcome__innerContent{
    margin: 40px auto;
    float: left;
}
.welcome__innerContent h3, .welcome__innerContent p, .welcome__innerContent h2{
    letter-spacing: 0.5px;
    color: var(--color-dimslaty);
    margin: 0;
}
.welcome__innerContent h3{
    font-size: 35px;
}
.welcome__innerContent p{
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 500;
}
.welcome__innerContent h2{
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 600;
}
@media screen and (max-width:1180px){
    .welcome__content{
        width: 95%;
    }
    .welcome__screen{
        grid-template-columns: 260px 10fr;
    }
    .welcome__innerContent h3{
        font-size: 28px;
    }
    .welcome__innerContent p{
        font-size: 16px;
    }
    .welcome__innerContent h2{
        font-size: 18px;
    }
    .welcome__logo h3{
        font-size: 50px;
    }
    .welcome__logo p{
        font-size: 14px;
    }
}
@media screen and (max-width:992px){
    .welcome__content img{
        width: 100%;
    }
}