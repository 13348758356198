.spinner {
  margin: 0 auto;
  margin-top: 150px;
  margin-bottom: 150px;
  width: 75px;
  text-align: center;
}

.spinner > div {
  width: 16px;
  height: 16px;
  /* background-color: #cf2336; */
  background-color: var(--color-primary);
  border-radius: 100%;
  display: inline-block;
  margin-left: 2px;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
  background: var(--color-primary);
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  /* background: #6e4287; */
  background: var(--color-primary);
}

.spinner .bounce0 {
  -webkit-animation-delay: -0.48s;
  animation-delay: -0.48s;
  /* background: #50af46; */
  background: var(--color-primary);
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
